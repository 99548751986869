import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import ArrowLeftBlackLarger from "../../../../../assets/images/arrow-left-black-larger.svg";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";

import AddSectionHeadStyles from "./AddSectionHeadStyles.module.css";

import GroupEmployeesSectionHeadList from "../groupemployeessectionheadlist/GroupEmployeesSectionHeadList.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import ConfirmAddSectionHeadToGroupPopup from "../confirmaddsectionheadtogrouppopup/ConfirmAddSectionHeadToGroupPopup.tsx";
import { Fade, Tooltip } from "@mui/material";
import { SearchBar } from "../../../../../ui/searchbar/SearchBar.tsx";
import SearchInputStyles from "../../../../../ui/searchbar/SearchInputStyles.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";

interface AddSectionHeadProps {
  setPopupMode: Dispatch<SetStateAction<ProjectTimesheetGroupPopupModeEnum>>;
  projectId: number;
  groupId: number;
}

const AddSectionHead: FC<AddSectionHeadProps> = ({ setPopupMode, projectId, groupId }) => {
  const [searchMember, setSearchMember] = useState<string>("");
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const [newSectionHead, setNewSectionHead] = useState<string>(""); // Added state for the selected employee code
  const [newSectionHeadName, setNewSectionHeadName] = useState<string>(""); // Added state for the selected employee name
  const [isAddSectionHeadButtonHovered, setIsAddGroupMemberButtonHovered] = useState<boolean>(false);
  const { getTimesheetGroupSectionHead } = useEpcmApiProjectsTimesheetsGroups();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const isAddSectionHeadButtonDisabled = useMemo(() => {
    return !newSectionHead;
  }, [newSectionHead]);

  const sectionHeadQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId],
    queryFn: () => getTimesheetGroupSectionHead(projectId, groupId),
    enabled: isAuthorized,
  });

  const sectionHeadData = useMemo(() => {
    return sectionHeadQuery.data;
  }, [sectionHeadQuery]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId] });
    };
  }, [projectId, groupId, queryClient]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
          onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.view)}
        >
          <img src={ArrowLeftBlackLarger} alt={"timesheet-group-settings-back-icon"} />
        </div>
        <div className={classNames(AddSectionHeadStyles.backText)}>Set Section Head</div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
        <SearchBar
          searchBarContainerStyles={classNames(
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap05,
            SearchInputStyles.searchInputContainer,
          )}
          setSearchQuery={setSearchMember}
          placeholder={"Search for employee"}
        />
        <GroupEmployeesSectionHeadList
          projectId={projectId}
          newSectionHead={newSectionHead}
          setNewSectionHead={setNewSectionHead}
          setNewSectionHeadName={setNewSectionHeadName}
          searchMember={searchMember}
          existingSectionHead={sectionHeadData?.sectionHead?.code}
        />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          {isAddSectionHeadButtonDisabled ? (
            <Tooltip title={"Select section head first"} TransitionComponent={Fade} placement={"top"} arrow>
              <div className={classNames(AddSectionHeadStyles.addSectionBtn, AddSectionHeadStyles.addSectionBtnDisabled)}>Set as Section Head</div>
            </Tooltip>
          ) : (
            <div
              className={classNames(
                AddSectionHeadStyles.addSectionBtn,
                GlobalStyles.elementWithCursor,
                isAddSectionHeadButtonHovered && AddSectionHeadStyles.addSectionBtnHovered,
              )}
              onMouseEnter={() => setIsAddGroupMemberButtonHovered(true)}
              onMouseLeave={() => setIsAddGroupMemberButtonHovered(false)}
              onClick={() => onOpenPopup(ProjectsPagePopups.confirmAddSectionHeadToGroup, popupHandler)}
            >
              Set Section Head
            </div>
          )}
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div
            className={classNames(AddSectionHeadStyles.cancelBtn, GlobalStyles.elementWithCursor)}
            onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.view)}
          >
            Back
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.confirmAddSectionHeadToGroup)!.isOpen && (
        <ConfirmAddSectionHeadToGroupPopup
          newSectionHeadName={newSectionHeadName}
          setNewSectionHeadName={setNewSectionHeadName}
          setNewSectionHead={setNewSectionHead}
          sectionHeadCode={newSectionHead}
          timesheetGroupId={groupId}
          isOpen={popupHandler.get(ProjectsPagePopups.confirmAddSectionHeadToGroup)!.isOpen}
          projectId={Number(projectId!)}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.confirmAddSectionHeadToGroup, popupHandler);
            setPopupMode(ProjectTimesheetGroupPopupModeEnum.view);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.confirmAddSectionHeadToGroup)}
          setPopupMode={setPopupMode}
        />
      )}
    </div>
  );
};

export default AddSectionHead;
