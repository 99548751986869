import { PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import EmployeeProfilePopupStyles from "./EmployeeProfilePopup.module.css";
import classNames from "classnames";
import { EPCMDialogCyan } from "../../../../ui/epcmdialog/EPCMDialogCyan.tsx";
import { EmployeeExtraInfoEnum, EmployeeProfilePopupTypeEnum } from "../../../../types/employees/EmployeesTypes.ts";
import { useEpcmApiEmployees } from "../../../../apicalls/employees/useEpcmApiEmployees.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendEmployeeCategoryEnum, FrontendEmployeeDetailedType } from "../../../../types/apicallstypes/EmployeesApiTypes.ts";
import { convertDateToMilSecs, formatDate } from "../../../../utils/DateManipulation.ts";
import { ButtonBarRight } from "./viewonlyelements/buttonbarright/ButtonBarRight.tsx";
import { ButtonBarBottom } from "./viewonlyelements/buttonbarbottom/ButtonBarBottom.tsx";
import { EmployeeInfoBasic } from "./viewonlyelements/employeeinfobasic/EmployeeInfoBasic.tsx";
import { EmployeeInfoDetailed } from "./viewonlyelements/employeeinfodetailed/EmployeeInfoDetailed.tsx";
import { EmployeeLanguagesInfo } from "./viewonlyelements/employeelanguagesinfo/EmployeeLanguagesInfo.tsx";
import { EmployeeInfoExtra } from "./viewonlyelements/employeeinfoextra/EmployeeInfoExtra.tsx";
import { FormButtonBarBottom } from "./inputelements/formbuttonbarbottom/FormButtonBarBottom.tsx";
import { FormEmployeeInfoBasic } from "./inputelements/formemployeeinfobasic/FormEmployeeInfoBasic.tsx";
import { FormEmployeeInfoDetailed } from "./inputelements/formemployeeinfodetailed/FormEmployeeInfoDetailed.tsx";
import { capitalizeAllFirstLetters } from "../../../../utils/StringManipulation.ts";
import { FormEmployeeLanguagesInfo } from "./inputelements/formemployeelanguagesinfo/FormEmployeeLanguagesInfo.tsx";
import { FormEmployeeInfoExtra } from "./inputelements/formemployeeinfoextra/FormEmployeeInfoExtra.tsx";
import { ApiRequestBodyEmployeeAdd, ApiRequestBodyEmployeeUpdate } from "epcm-common/dist/Types/EmployeeTypes.ts";
import { convertEmployeeCategoryToBackend } from "../../../../types/apicallstypes/apicallstypesconverters/EmployeesApiConverter.ts";
import { useEpcmApiEmployeesMutations } from "../../../../apicalls/employees/mutations/useEpcmApiEmployeesMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { Skeleton } from "@mui/material";

import { FrontendGlobalPositionType } from "../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../types/Roles.ts";

interface EmployeeProfilePopupProps extends PopupType {
  popupTypeFromParent: EmployeeProfilePopupTypeEnum;
  employeeId: number | null;
}

export const EmployeeProfilePopup: FC<EmployeeProfilePopupProps> = ({ isOpen, closeFn, employeeId, popupTypeFromParent }) => {
  const { getEmployee } = useEpcmApiEmployees();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { useCreateEmployeeMutation, useUpdateEmployeeMutation } = useEpcmApiEmployeesMutations();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const [popupType, setPopupType] = useState<EmployeeProfilePopupTypeEnum>(popupTypeFromParent);
  const [employeeName, setEmployeeName] = useState<string | null>(null);
  const [employeeCode, setEmployeeCode] = useState<string | null>(null);
  const [employeeNationality, setEmployeeNationality] = useState<string | null>(null);
  const [employeePosition, setEmployeePosition] = useState<FrontendGlobalPositionType | null>(null);
  const [employeeCategory, setEmployeeCategory] = useState<FrontendEmployeeCategoryEnum | null>(null);
  const [employeeDateOfBirth, setEmployeeDateOfBirth] = useState<string | null>(null);
  const [employeeLanguages, setEmployeeLanguages] = useState<string[] | null>(null);
  const [employeeQualifications, setEmployeeQualifications] = useState<string | null>(null);
  const [employeeCertifications, setEmployeeCertifications] = useState<string | null>(null);
  const [employeeExperience, setEmployeeExperience] = useState<string | null>(null);
  const [isEmployeeInfoInitialized, setIsEmployeeInfoInitialized] = useState<boolean>(false);

  const canEditEmployee = canPerformGlobalAction(GlobalAction.EmployeeUpdate);
  const canDeleteEmployee = canPerformGlobalAction(GlobalAction.EmployeeDelete);

  const employeeQuery = useQuery({
    queryKey: [QueryNames.Employees, employeeId],
    queryFn: () => getEmployee(employeeId!, DetailLevel.DETAILED),
    enabled: isAuthorized && popupTypeFromParent !== EmployeeProfilePopupTypeEnum.create && !!employeeId,
  });

  const employeeData = useMemo(() => {
    return employeeQuery.data ? (employeeQuery.data as FrontendEmployeeDetailedType) : undefined;
  }, [employeeQuery]);

  const createEmployeesMutation = useCreateEmployeeMutation({
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Employee was created successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateEmployeesMutation = useUpdateEmployeeMutation(employeeId!, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setPopupType(EmployeeProfilePopupTypeEnum.view);
      setResponseObject({ status: data.data.status, message: "Employee was edited successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const isViewOnlyMode = useMemo(() => {
    return popupType === EmployeeProfilePopupTypeEnum.view;
  }, [popupType]);

  const isCreateMode = useMemo(() => {
    return popupType === EmployeeProfilePopupTypeEnum.create;
  }, [popupType]);

  const isEditMode = useMemo(() => {
    return popupType === EmployeeProfilePopupTypeEnum.edit;
  }, [popupType]);

  const hasEmptyFields =
    useMemo(() => {
      if (isCreateMode) {
        return !employeeName?.trim() || !employeeCode?.trim();
      } else if (isEditMode && employeeData) {
        return !employeeName?.trim() || !employeeCode?.trim();
      }
    }, [employeeName, employeeCode, isCreateMode, isEditMode, employeeData]) ?? false;

  const areLanguagesTheSame = useMemo(() => {
    if (employeeLanguages && employeeData?.languages) {
      return JSON.stringify(employeeLanguages) === JSON.stringify(employeeData.languages);
    }
    if ((!employeeLanguages || employeeLanguages.length === 0) && !employeeData?.languages) {
      return true;
    }
  }, [employeeLanguages, employeeData]);
  const hasFormChanges = useMemo(() => {
    if (isCreateMode) {
      return !(
        !employeeName &&
        !employeeCode &&
        !employeeNationality &&
        !employeePosition &&
        !employeeCategory &&
        !employeeDateOfBirth &&
        !employeeLanguages &&
        !employeeQualifications &&
        !employeeCertifications &&
        !employeeExperience
      );
    }
    if (isEditMode && employeeData) {
      return !(
        employeeName === employeeData.name &&
        employeeCode === employeeData.code &&
        employeeNationality?.toLowerCase() === (employeeData.nationality ? employeeData.nationality.toLowerCase() : "") &&
        employeePosition?.id === (employeeData?.globalPosition ? employeeData.globalPosition.id : "") &&
        employeeCategory === employeeData.category &&
        (employeeDateOfBirth
          ? formatDate(new Date(employeeDateOfBirth)) === (employeeData.dateOfBirth ? formatDate(employeeData.dateOfBirth) : null)
          : false) &&
        areLanguagesTheSame &&
        employeeQualifications === employeeData.qualifications &&
        employeeCertifications === employeeData.certifications &&
        employeeExperience === employeeData.experience
      );
    }
    return false;
  }, [
    employeeData,
    isCreateMode,
    isEditMode,
    employeeName,
    employeeCode,
    employeeNationality,
    employeePosition,
    employeeCategory,
    employeeDateOfBirth,
    employeeLanguages,
    employeeQualifications,
    employeeCertifications,
    employeeExperience,
    areLanguagesTheSame,
  ]);

  const isCreateFormValid = useMemo(() => {
    return employeeName && employeeCode;
  }, [employeeName, employeeCode]);

  const submitButtonTooltipText: string = useMemo(() => {
    return isCreateMode && !isCreateFormValid ? "Mandatory info missing" : isEditMode && !hasFormChanges ? "No changes" : "";
  }, [isCreateMode, isEditMode, isCreateFormValid, hasFormChanges]);

  const initializeEmployeeInfo = useCallback((employeeData: FrontendEmployeeDetailedType) => {
    setEmployeeName(employeeData.name);
    setEmployeeCode(employeeData.code);
    setEmployeeNationality(employeeData.nationality);
    setEmployeePosition(employeeData.globalPosition);
    setEmployeeCategory(employeeData.category);
    setEmployeeDateOfBirth(employeeData.dateOfBirth ? formatDate(employeeData.dateOfBirth) : null);
    setEmployeeLanguages(employeeData.languages);
    setEmployeeQualifications(employeeData.qualifications);
    setEmployeeCertifications(employeeData.certifications);
    setEmployeeExperience(employeeData.experience);
    setIsEmployeeInfoInitialized(true);
  }, []);

  const onFormSubmit = useCallback(() => {
    if (isCreateMode) {
      if (!isCreateFormValid) {
        return;
      }

      const createMutationData: ApiRequestBodyEmployeeAdd = {
        name: employeeName!,
        nationality: employeeNationality ?? undefined,
        type: employeeCategory ? convertEmployeeCategoryToBackend(employeeCategory!) : undefined,
        code: employeeCode!,
        globalPositionId: employeePosition?.id ?? undefined,
        dateOfBirth: employeeDateOfBirth ? convertDateToMilSecs(new Date(employeeDateOfBirth!)) : undefined,
        languages: employeeLanguages !== null ? (employeeLanguages?.length > 0 ? employeeLanguages!.join(",") : "") : undefined,
        qualifications: employeeQualifications ?? undefined,
        certifications: employeeCertifications ?? undefined,
        experience: employeeExperience ?? undefined,
      };
      createEmployeesMutation.mutate(createMutationData);
      onOpenLoaderPopup();
    } else if (isEditMode) {
      if (!hasFormChanges) {
        return;
      }

      const updateMutationData: ApiRequestBodyEmployeeUpdate = {
        name: employeeName && employeeName !== employeeData!.name ? employeeName : undefined,
        code: employeeCode && employeeCode !== employeeData!.code ? employeeCode : undefined,
        nationality:
          employeeNationality &&
          capitalizeAllFirstLetters(employeeNationality) !== capitalizeAllFirstLetters(employeeData?.nationality ? employeeData!.nationality : "")
            ? employeeNationality
            : undefined,
        type: employeeCategory && employeeCategory !== employeeData!.category ? convertEmployeeCategoryToBackend(employeeCategory) : undefined,
        globalPositionId: employeePosition && employeePosition.id !== employeeData?.globalPosition?.id ? employeePosition.id : undefined,
        languages: employeeLanguages && employeeLanguages !== employeeData!.languages ? employeeLanguages.join(",") : undefined,
        qualifications: employeeQualifications && employeeQualifications !== employeeData?.qualifications ? employeeQualifications : undefined,
        certifications: employeeCertifications && employeeCertifications !== employeeData?.certifications ? employeeCertifications : undefined,
        experience: employeeExperience && employeeExperience !== employeeData?.experience ? employeeExperience : undefined,
        dateOfBirthTimestamp: employeeDateOfBirth ? convertDateToMilSecs(new Date(employeeDateOfBirth!)) : undefined,
      } satisfies ApiRequestBodyEmployeeUpdate;

      updateEmployeesMutation.mutate(updateMutationData);
      onOpenLoaderPopup();
    }
  }, [
    isCreateFormValid,
    employeeData,
    hasFormChanges,
    isCreateMode,
    isEditMode,
    employeeName,
    employeeCode,
    employeeNationality,
    employeePosition,
    employeeCategory,
    employeeDateOfBirth,
    employeeLanguages,
    employeeQualifications,
    employeeCertifications,
    employeeExperience,
    createEmployeesMutation,
    updateEmployeesMutation,
    onOpenLoaderPopup,
  ]);

  const employeeProfileSkeletonLoader = (
    <div className={classNames(EmployeeProfilePopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <Skeleton variant="rounded" height={"75%"} width={330} />
          <Skeleton variant="rounded" height={"25%"} width={330} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
          <Skeleton variant="rounded" height={"50%"} width={"100%"} />
          <Skeleton variant="rounded" height={"50%"} width={"100%"} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
          <Skeleton variant="rounded" height={"50%"} width={"100%"} />
          <Skeleton variant="rounded" height={"50%"} width={0} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <Skeleton variant="rounded" height={50} width={330} />
        <div className={classNames(GlobalStyles.flex1)} />
        <Skeleton variant="rounded" height={50} width={150} />
      </div>
    </div>
  );

  useEffect(() => {
    if (employeeData && ((popupType === popupTypeFromParent && !isEmployeeInfoInitialized) || !hasFormChanges)) {
      initializeEmployeeInfo(employeeData);
    }
  }, [employeeData, popupType, popupTypeFromParent, isEmployeeInfoInitialized, hasFormChanges, initializeEmployeeInfo]);

  useEffect(() => {
    return () => {
      popupTypeFromParent !== EmployeeProfilePopupTypeEnum.create &&
        employeeId &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.Employees, employeeId] })
          .then(() => console.log(`In employee with id ${employeeId} profile card, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient, employeeId, popupTypeFromParent]);

  return (
    <EPCMDialogCyan open={isOpen} onClose={closeFn} maxWidth={false}>
      {isCreateMode || employeeData ? (
        <div
          className={classNames(
            EmployeeProfilePopupStyles.contentContainer,
            isViewOnlyMode && EmployeeProfilePopupStyles.viewOnlyContentContainer,
            GlobalStyles.flex,
            GlobalStyles.gap,
          )}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
            <div
              className={classNames(
                EmployeeProfilePopupStyles.mainContentContainer,
                GlobalStyles.flex,
                GlobalStyles.flex1,
                GlobalStyles.gap,
                GlobalStyles.overflowHiddenFullHeight,
              )}
            >
              <div
                className={classNames(
                  EmployeeProfilePopupStyles.employeeInfoLeftPanel,
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap,
                )}
              >
                <div className={classNames(EmployeeProfilePopupStyles.employeeInfoContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(EmployeeProfilePopupStyles.employeeBasicInfoContainer)}>
                    {isViewOnlyMode ? (
                      <EmployeeInfoBasic employeeName={employeeName!} employeeCode={employeeCode!} />
                    ) : (
                      <FormEmployeeInfoBasic
                        employeeName={employeeName ?? ""}
                        employeeCode={employeeCode ?? ""}
                        onEmployeeNameChange={(eventValue) => setEmployeeName(eventValue && eventValue !== "" ? eventValue : null)}
                        onEmployeeCodeChange={(eventValue) => setEmployeeCode(eventValue && eventValue !== "" ? eventValue : null)}
                      />
                    )}
                  </div>
                  {isViewOnlyMode ? (
                    <EmployeeInfoDetailed
                      employeePosition={employeePosition ?? null}
                      employeeCategory={employeeCategory ?? ""}
                      employeeNationality={employeeNationality ?? ""}
                      employeeDateOfBirth={employeeDateOfBirth ?? ""}
                    />
                  ) : !isViewOnlyMode ? (
                    <FormEmployeeInfoDetailed
                      isViewMode={isViewOnlyMode}
                      employeePosition={employeePosition}
                      employeeCategory={employeeCategory}
                      employeeNationality={employeeNationality ? capitalizeAllFirstLetters(employeeNationality) : ""}
                      employeeDateOfBirth={employeeDateOfBirth ? formatDate(new Date(employeeDateOfBirth)) : ""}
                      onEmployeePositionChange={(eventValue) =>
                        setEmployeePosition(
                          eventValue.id != null
                            ? {
                                id: eventValue.id,
                                code: eventValue.code,
                                description: eventValue.description,
                              }
                            : null,
                        )
                      }
                      onEmployeeCategoryChange={(eventValue) => setEmployeeCategory(eventValue)}
                      onEmployeeNationalityChange={(eventValue) => setEmployeeNationality(eventValue && eventValue !== "" ? eventValue : null)}
                      onEmployeeDateOfBirthChange={(eventValue) => {
                        setEmployeeDateOfBirth(eventValue && eventValue.toString());
                      }}
                    />
                  ) : null}
                </div>
                <div className={classNames(EmployeeProfilePopupStyles.employeeInfoContainer)}>
                  {isViewOnlyMode && employeeLanguages ? (
                    <EmployeeLanguagesInfo employeeLanguages={employeeLanguages} />
                  ) : !isViewOnlyMode ? (
                    <FormEmployeeLanguagesInfo
                      employeeLanguages={employeeLanguages ?? []}
                      onEmployeeLanguagesChange={(languagesNewState) => setEmployeeLanguages(languagesNewState)}
                    />
                  ) : null}
                </div>
              </div>
              <div
                className={classNames(
                  EmployeeProfilePopupStyles.employeeExtraInfoContainer,
                  GlobalStyles.flex,
                  GlobalStyles.flex2,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap3,
                  GlobalStyles.overflowHiddenFullHeight,
                )}
              >
                {isViewOnlyMode ? (
                  <EmployeeInfoExtra extraInfoType={EmployeeExtraInfoEnum.qualifications} employeeExtraInfo={employeeQualifications} />
                ) : (
                  <FormEmployeeInfoExtra
                    extraInfoType={EmployeeExtraInfoEnum.qualifications}
                    employeeExtraInfo={employeeQualifications ?? ""}
                    onEmployeeExtraInfoChange={(eventValue) => setEmployeeQualifications(eventValue !== "" ? eventValue : null)}
                  />
                )}
                {isViewOnlyMode ? (
                  <EmployeeInfoExtra extraInfoType={EmployeeExtraInfoEnum.certifications} employeeExtraInfo={employeeCertifications} />
                ) : (
                  <FormEmployeeInfoExtra
                    extraInfoType={EmployeeExtraInfoEnum.certifications}
                    employeeExtraInfo={employeeCertifications ?? ""}
                    onEmployeeExtraInfoChange={(eventValue) => setEmployeeCertifications(eventValue !== "" ? eventValue : null)}
                  />
                )}
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flex2, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3)}>
                {isViewOnlyMode ? (
                  <EmployeeInfoExtra extraInfoType={EmployeeExtraInfoEnum.experience} employeeExtraInfo={employeeExperience} />
                ) : (
                  <FormEmployeeInfoExtra
                    extraInfoType={EmployeeExtraInfoEnum.experience}
                    employeeExtraInfo={employeeExperience ?? ""}
                    onEmployeeExtraInfoChange={(eventValue) => setEmployeeExperience(eventValue !== "" ? eventValue : null)}
                  />
                )}
                <div className={classNames(GlobalStyles.flex1)} />
              </div>
            </div>
            {isViewOnlyMode ? (
              <ButtonBarBottom switchToEditMode={() => setPopupType(EmployeeProfilePopupTypeEnum.edit)} canEditEmployee={canEditEmployee} />
            ) : (
              <FormButtonBarBottom
                formType={popupType}
                hasFormChanges={hasFormChanges}
                isSubmitDisabled={(isCreateMode && (!isCreateFormValid || hasEmptyFields)) || (isEditMode && (!hasFormChanges || hasEmptyFields))}
                submitButtonTooltipText={submitButtonTooltipText}
                submitFn={onFormSubmit}
                cancelFn={() => {
                  if (isCreateMode || popupType === popupTypeFromParent) {
                    closeFn();
                  } else {
                    initializeEmployeeInfo(employeeData!);
                    setPopupType(EmployeeProfilePopupTypeEnum.view);
                  }
                }}
              />
            )}
          </div>
          {isViewOnlyMode && <ButtonBarRight onCloseFn={closeFn} canDeleteEmployee={canDeleteEmployee} />}
        </div>
      ) : (
        employeeProfileSkeletonLoader
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            isCreateMode && responseType === ApiResponseTypeEnum.success && closeFn();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </EPCMDialogCyan>
  );
};
