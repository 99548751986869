import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { EPCMInfoContainerDiv } from "../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import TimesheetGroupsButtonsStyles from "./TimesheetGroupsButtonsStyles.module.css";
import TimesheetsPlusIcon from "../../../../../assets/images/timesheet-plus-icon.svg";
import TimesheetGroupsWhitePlusIcon from "../../../../../assets/images/timesheet-groups-white-plus-icon.svg";
import { useEffect, useMemo, useState } from "react";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import ViewUngroupedEmployees from "../../../popups/viewungroupedemployees/ViewUngroupedEmployees.tsx";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import CreateNewProjectGroup from "../../../popups/createnewprojectgroup/CreateNewProjectGroup.tsx";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames, SubQueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import CodeSkeletonLoader from "../../../popups/timesheetgroupsettingspopup/loaders/CodeSkeletonLoader.tsx";
import { Fade, Tooltip } from "@mui/material";
import { useEpcmProjectTimesheetAnalytics } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsanalytics/useEpcmProjectTimesheetAnalytics.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import { useHandleUnauthorized } from "../../../../../utils/use-handle-unauthorized.ts";
import { FrontendTimesheetCountersEnum, ProjectTimesheetGroupModificationTypeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";

const TimesheetGroupsButtons = () => {
  const { currentProject } = useProjectContext();
  const queryClient = useQueryClient();
  const { popupHandler, popupHeaders, onOpenPopup, onClosePopup } = useProjectsPopups();
  const { projectId, groupId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectEmployeesByPeriodCount } = useEpcmProjectTimesheetAnalytics();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const allProjectUngroupedEmployeesCountQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, parseInt(projectId!), SubQueryNames.count],
    queryFn: () => getAllProjectEmployeesByPeriodCount(parseInt(projectId!), FrontendTimesheetCountersEnum.UNGROUPED).catch(handleErrorRedirect),
    enabled: isAuthorized,
  });

  const allProjectUngroupedEmployeesCountData = useMemo(() => {
    return allProjectUngroupedEmployeesCountQuery.data;
  }, [allProjectUngroupedEmployeesCountQuery.data]);

  const allProjectUngroupedEmployeesCount = useMemo(() => {
    return allProjectUngroupedEmployeesCountData?.count;
  }, [allProjectUngroupedEmployeesCountData]);

  const handleViewUngroupedEmployees = () => {
    if (allProjectUngroupedEmployeesCountData?.count && allProjectUngroupedEmployeesCountData?.count > 0) {
      onOpenPopup(ProjectsPagePopups.viewUngroupedEmployees, popupHandler);
    }
  };

  const canCreateProjectTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupCreate);
  const canUpdateProjectTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupUpdate);
  const canAddEmployeeToProjectTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetAddToGroup);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, parseInt(projectId!), SubQueryNames.count] })
        .then(() => {});
    };
  }, [queryClient, projectId]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.centerHorizontal, GlobalStyles.overflowHiddenFullHeight)}>
      <Tooltip
        title={allProjectUngroupedEmployeesCount === 0 ? "No ungrouped employees" : undefined}
        placement="top"
        TransitionComponent={Fade}
        arrow
      >
        <EPCMInfoContainerDiv
          onClick={() => {
            if (canAddEmployeeToProjectTimesheetGroup && canUpdateProjectTimesheetGroup) {
              handleViewUngroupedEmployees();
            } else {
              return;
            }
          }}
          className={classNames(
            allProjectUngroupedEmployeesCount &&
              canAddEmployeeToProjectTimesheetGroup &&
              canUpdateProjectTimesheetGroup &&
              allProjectUngroupedEmployeesCount > 0 &&
              TimesheetGroupsButtonsStyles.largeButton,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
            GlobalStyles.overflowHiddenFullHeight,
          )}
        >
          <div>
            {!allProjectUngroupedEmployeesCountQuery.isLoading ? (
              <div className={classNames(TimesheetGroupsButtonsStyles.boldText)}>
                {allProjectUngroupedEmployeesCountQuery.data ? allProjectUngroupedEmployeesCount : ""}
              </div>
            ) : (
              <CodeSkeletonLoader />
            )}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div className={classNames(TimesheetGroupsButtonsStyles.textContainer, GlobalStyles.flex, GlobalStyles.flex1)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, TimesheetGroupsButtonsStyles.subText)}>
              <div className={classNames(TimesheetGroupsButtonsStyles.subTextSmall)}>Ungrouped</div>
              <div> Employees</div>
            </div>
          </div>
        </EPCMInfoContainerDiv>
      </Tooltip>

      {canCreateProjectTimesheetGroup && (
        <EPCMInfoContainerDiv
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
            GlobalStyles.overflowHiddenFullHeight,
            TimesheetGroupsButtonsStyles.largeButton,
          )}
          onClick={() => onOpenPopup(ProjectsPagePopups.createProjectGroup, popupHandler)}
          onMouseEnter={() => setIsSelected(true)}
          onMouseLeave={() => setIsSelected(false)}
        >
          <div>
            <div className={classNames(TimesheetGroupsButtonsStyles.boldText)}>
              <div>
                <img src={isSelected ? TimesheetGroupsWhitePlusIcon : TimesheetsPlusIcon} alt="Timesheets" />
              </div>
            </div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

          <div className={classNames(TimesheetGroupsButtonsStyles.textContainer, GlobalStyles.flex, GlobalStyles.flex1)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, TimesheetGroupsButtonsStyles.subText)}>
              <div className={classNames(TimesheetGroupsButtonsStyles.subTextSmall)}>Create</div>
              <div> New Group</div>
            </div>
          </div>
        </EPCMInfoContainerDiv>
      )}
      {popupHandler.get(ProjectsPagePopups.viewUngroupedEmployees)!.isOpen && (
        <ViewUngroupedEmployees
          projectId={Number(projectId)!}
          groupId={Number(groupId)!}
          isOpen={popupHandler.get(ProjectsPagePopups.viewUngroupedEmployees)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.viewUngroupedEmployees, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.viewUngroupedEmployees)}
          secondaryHeaderText={currentProject?.name}
        />
      )}
      {popupHandler.get(ProjectsPagePopups.createProjectGroup)!.isOpen && (
        <CreateNewProjectGroup
          mode={ProjectTimesheetGroupModificationTypeEnum.create}
          projectId={Number(projectId)!}
          isOpen={popupHandler.get(ProjectsPagePopups.createProjectGroup)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.createProjectGroup, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.createProjectGroup)}
        />
      )}
    </div>
  );
};
export default TimesheetGroupsButtons;
