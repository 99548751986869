import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetAnalyticsListButtonItem from "./timesheetanalyticslistbuttonitem/TimesheetAnalyticsListButtonItem.tsx";
import { FC, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { QueryNames, SubQueryNames } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmProjectTimesheetAnalytics } from "../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsanalytics/useEpcmProjectTimesheetAnalytics.ts";
import {
  FrontendTimesheetCountersEnum,
  ProjectTimesheetAnalyticsListButtonTypesEnum,
} from "../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { FrontendTimesheetStatusEnum } from "../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { convertDateToMilSecs } from "../../../../../../../utils/DateManipulation.ts";

interface TimesheetAnalyticsListButtonProps {
  selectedPeriodStart: Date | null;
  selectedPeriodEnd: Date | null;
  projectId: number;
}

const TimesheetAnalyticsListButtons: FC<TimesheetAnalyticsListButtonProps> = ({ selectedPeriodStart, selectedPeriodEnd, projectId }) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectEmployeesByPeriodCount, getAllProjectTimesheetDetailedData } = useEpcmProjectTimesheetAnalytics();

  const allProjectEmployeesCountQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.count],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(projectId, undefined, convertDateToMilSecs(selectedPeriodStart!), convertDateToMilSecs(selectedPeriodEnd!)),
    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const allProjectEmployeesCountData = useMemo(() => {
    return allProjectEmployeesCountQuery.data;
  }, [allProjectEmployeesCountQuery.data]);

  const projectTimesheetEmployeesActiveTotalQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.active],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        FrontendTimesheetCountersEnum.ACTIVE,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
      ),

    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetActiveEmployeesCountData = useMemo(() => {
    return projectTimesheetEmployeesActiveTotalQuery.data;
  }, [projectTimesheetEmployeesActiveTotalQuery.data]);

  const projectTimesheetEmployeesNotActiveTotalQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.notActive],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        FrontendTimesheetCountersEnum.NOT_ACTIVE,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
      ),

    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetNotActiveEmployeesCountData = useMemo(() => {
    return projectTimesheetEmployeesNotActiveTotalQuery.data;
  }, [projectTimesheetEmployeesNotActiveTotalQuery.data]);

  const projectTimesheetItemsQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsItems, projectId, selectedPeriodStart, selectedPeriodEnd],
    queryFn: () =>
      getAllProjectTimesheetDetailedData(projectId, convertDateToMilSecs(selectedPeriodStart!), convertDateToMilSecs(selectedPeriodEnd!)),

    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetItemsData = useMemo(() => {
    return projectTimesheetItemsQuery.data;
  }, [projectTimesheetItemsQuery.data]);

  const projectTimesheetItemsApprovedData = useMemo(() => {
    return projectTimesheetItemsQuery.data?.statusCounters.filter((statusCounter) => statusCounter.status === FrontendTimesheetStatusEnum.APPROVED)[0]
      .count;
  }, [projectTimesheetItemsQuery.data]);

  const projectTimesheetItemsNonApprovedData = useMemo(() => {
    const nonApprovedCounts = projectTimesheetItemsQuery.data?.statusCounters.filter(
      (statusCounter) => statusCounter.status !== FrontendTimesheetStatusEnum.APPROVED,
    );
    const sumOfNonApproved = nonApprovedCounts?.reduce((acc, curr) => acc + curr.count, 0);
    return sumOfNonApproved;
  }, [projectTimesheetItemsQuery.data]);

  const projectTimesheetEmployeesInTimesheetQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.inTimesheet],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        FrontendTimesheetCountersEnum.IN_TIMESHEET,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
      ),
    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetEmployeesInTimesheetData = useMemo(() => {
    return projectTimesheetEmployeesInTimesheetQuery.data;
  }, [projectTimesheetEmployeesInTimesheetQuery.data]);

  const projectTimesheetEmployeesNotInTimesheetQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.notInTimesheet],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        FrontendTimesheetCountersEnum.NOT_IN_TIMESHEET,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
      ),
    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetEmployeesNotInTimesheetData = useMemo(() => {
    return projectTimesheetEmployeesNotInTimesheetQuery.data;
  }, [projectTimesheetEmployeesNotInTimesheetQuery.data]);

  useEffect(() => {
    return () => {
      if (selectedPeriodStart && selectedPeriodEnd) {
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.count],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for project ${projectId} regarding employees count cancelled`);
          });
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.active],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for active employees cancelled`);
          });
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.notActive],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for not-active employees cancelled`);
          });

        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsItems, projectId, selectedPeriodStart, selectedPeriodEnd],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsItems} for project ${projectId} regarding timesheet items cancelled`);
          });
      }
    };
  }, [queryClient, projectId, selectedPeriodStart, selectedPeriodEnd]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
      {
        <TimesheetAnalyticsListButtonItem
          mainText={"Employees \n Total"}
          primaryCount={allProjectEmployeesCountData?.count}
          firstSubtext={"With Active MMR"}
          firstSubtextCount={projectTimesheetActiveEmployeesCountData?.count}
          secondSubtext={"No Active MMR"}
          secondSubtextCount={projectTimesheetNotActiveEmployeesCountData?.count}
          timesheetAnalyticsButtonType={ProjectTimesheetAnalyticsListButtonTypesEnum.none}
          projectId={projectId}
          selectedPeriodStart={selectedPeriodStart}
          selectedPeriodEnd={selectedPeriodEnd}
        />
      }
      {
        <TimesheetAnalyticsListButtonItem
          mainText={"Employees Total\nWith Active MMR"}
          primaryCount={projectTimesheetActiveEmployeesCountData?.count}
          firstSubtext={"In timesheets"}
          firstSubtextCount={projectTimesheetEmployeesInTimesheetData?.count}
          secondSubtext={"Not in timesheets"}
          secondSubtextCount={projectTimesheetEmployeesNotInTimesheetData?.count}
          timesheetAnalyticsButtonType={ProjectTimesheetAnalyticsListButtonTypesEnum.timesheetEmployees}
          projectId={projectId}
          selectedPeriodStart={selectedPeriodStart}
          selectedPeriodEnd={selectedPeriodEnd}
        />
      }

      {
        <TimesheetAnalyticsListButtonItem
          mainText={"Timesheets \n Total"}
          primaryCount={projectTimesheetItemsData?.totalCount}
          firstSubtext={"Approved"}
          firstSubtextCount={projectTimesheetItemsApprovedData}
          secondSubtext={"Non-Approved"}
          secondSubtextCount={projectTimesheetItemsNonApprovedData}
          timesheetAnalyticsButtonType={ProjectTimesheetAnalyticsListButtonTypesEnum.timesheetItems}
          projectId={projectId}
          selectedPeriodStart={selectedPeriodStart}
          selectedPeriodEnd={selectedPeriodEnd}
        />
      }
    </div>
  );
};
export default TimesheetAnalyticsListButtons;
