import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ConfigurationPositionStyles from "./ConfigurationPositions.module.css";
import SearchBarSubHeader from "../searchbarsubheader/SearchBarSubHeader.tsx";
import ConfigurationEntityTableManager from "../configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import ConfigurationPositionsPopup from "../popups/configurationpositionspopup/ConfigurationPositionsPopup.tsx";

import NewPositionWrenchIcon from "../../../../../assets/images/configuration/new-position-wrench-icon.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import { ConfigurationScreenType, configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { ConfigurationTargetType } from "../../../../../types/settings/GlobalConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

const ConfigurationPositions = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { currentProject } = useProjectContext();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canCreateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigCreate);

  return (
    <div
      className={classNames(
        ConfigurationPositionStyles.contentContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
          ConfigurationPositionStyles.nestedContainerPadding,
        )}
      >
        {canListConfiguration && <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Positions"} />}
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationPositionStyles.mainContent)}>
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              configurationScreenType={ConfigurationScreenType.positions}
              target={ConfigurationTargetType.project}
            />
          </div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, ConfigurationPositionStyles.actionBtnContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        {canCreateConfiguration && (
          <div
            onClick={() => onOpenPopup(ProjectsPagePopups.addProjectPosition, popupHandler)}
            className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
          >
            <div>New Position Type</div>
            <div className={classNames(ConfigurationPositionStyles.actionBtnImage)}>
              <img src={NewPositionWrenchIcon} alt="New Position Type" />
            </div>
          </div>
        )}
      </div>
      {popupHandler.get(ProjectsPagePopups.addProjectPosition)!.isOpen && (
        <ConfigurationPositionsPopup
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectPosition, popupHandler)}
          mode={configurationsPopupType.create}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectPosition)!.isOpen}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectPosition)}
          secondaryHeaderText={currentProject.name}
        />
      )}
    </div>
  );
};
export default ConfigurationPositions;
