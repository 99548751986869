import {
  FrontendApiRequestBodyTimesheetRecordSave,
  FrontendBodyTimesheetRecordSave,
  FrontendCtrBasicType,
  FrontendCtrCategoryEnum,
  FrontendCtrLimitedType,
  FrontendCtrNormalType,
  FrontendCtrTypeEnum,
  FrontendMmrCalendarDayBodyType,
  FrontendTimesheetEmployeeBodyType,
} from "../CtrTypes.ts";
import { CtrBasic, CtrCategory, CtrLimited, CtrNormal, CtrType } from "epcm-common/dist/Types/CtrTypes";
import {
  ApiRequestBodyTimesheetSave,
  MmrBodyTimesheetRecordSave,
  MmrCalendarDayBody,
  TimesheetEmployeeBody,
} from "epcm-common/dist/Types/TimesheetRecordTypes";
import { MmrCtrsBody } from "epcm-common/dist/Types/TimesheetRecordTypes.ts";
import { convertProjectTimesheetStatusToBackend } from "./projects/projecttimesheets/ProjectTimesheetsApiConverters.ts";

export const convertCtrCategoryToFrontend = (ctrCategory: CtrCategory): FrontendCtrCategoryEnum => {
  const ctrCategoryMap: Map<CtrCategory, FrontendCtrCategoryEnum> = new Map([
    [CtrCategory.COMMON_PROJECT, FrontendCtrCategoryEnum.COMMON_PROJECT],
    [CtrCategory.ENGINEERING, FrontendCtrCategoryEnum.ENGINEERING],
    [CtrCategory.PROCUREMENT, FrontendCtrCategoryEnum.PROCUREMENT],
    [CtrCategory.CONSTRUCTION_PRECOMM_COMMISSIONING, FrontendCtrCategoryEnum.CONSTRUCTION_PRECOMM_COMMISSIONING],
  ]);
  return ctrCategoryMap.get(ctrCategory)!;
};

export const convertCtrCategoryToBackend = (ctrCategory: FrontendCtrCategoryEnum): CtrCategory => {
  const ctrCategoryMap: Map<FrontendCtrCategoryEnum, CtrCategory> = new Map([
    [FrontendCtrCategoryEnum.COMMON_PROJECT, CtrCategory.COMMON_PROJECT],
    [FrontendCtrCategoryEnum.ENGINEERING, CtrCategory.ENGINEERING],
    [FrontendCtrCategoryEnum.PROCUREMENT, CtrCategory.PROCUREMENT],
    [FrontendCtrCategoryEnum.CONSTRUCTION_PRECOMM_COMMISSIONING, CtrCategory.CONSTRUCTION_PRECOMM_COMMISSIONING],
  ]);
  return ctrCategoryMap.get(ctrCategory)!;
};

export const convertCtrTypeToFrontend = (ctrType: CtrType): FrontendCtrTypeEnum => {
  const ctrTypeMap: Map<CtrType, FrontendCtrTypeEnum> = new Map([
    [CtrType.DEL, FrontendCtrTypeEnum.DEL],
    [CtrType.ACT, FrontendCtrTypeEnum.ACT],
    [CtrType.MISC, FrontendCtrTypeEnum.MISC],
  ]);
  return ctrTypeMap.get(ctrType)!;
};

export const convertCtrTypeToBackend = (ctrType: FrontendCtrTypeEnum): CtrType => {
  const ctrTypeMap: Map<FrontendCtrTypeEnum, CtrType> = new Map([
    [FrontendCtrTypeEnum.DEL, CtrType.DEL],
    [FrontendCtrTypeEnum.ACT, CtrType.ACT],
    [FrontendCtrTypeEnum.MISC, CtrType.MISC],
  ]);
  return ctrTypeMap.get(ctrType)!;
};

export const convertCtrLimitedFrontend = (ctrData: CtrLimited): FrontendCtrLimitedType => {
  return {
    id: ctrData.id,
    code: ctrData.code,
  };
};

export const convertAllCtrsLimitedFrontend = (allCtrsData: CtrLimited[]): FrontendCtrLimitedType[] => {
  return allCtrsData.map((ctrData): FrontendCtrLimitedType => convertCtrLimitedFrontend(ctrData));
};

export const convertCtrBasicFrontend = (ctrData: CtrBasic): FrontendCtrBasicType => {
  return {
    ...convertCtrLimitedFrontend(ctrData),
    description: ctrData.description,
  };
};

export const convertCtrNormalFrontend = (ctrData: CtrNormal): FrontendCtrNormalType => {
  return {
    ...convertCtrBasicFrontend(ctrData),
    category: convertCtrCategoryToFrontend(ctrData.category),
    type: convertCtrTypeToFrontend(ctrData.type),
  };
};

export const convertMmrCalendarDayDataToBackend = (mmrCalendarDayData: FrontendMmrCalendarDayBodyType): MmrCalendarDayBody => {
  return {
    calendarDayId: mmrCalendarDayData.calendarDayId,
    isApplicable: mmrCalendarDayData.isApplicable,
    //records: mmrCalendarDayData.records.map((record) => convertBodyTimesheetRecordSaveDataToBackend(record)),
  };
};

export const convertMmrBodyTimesheetRecordSaveDataToBackend = (
  mmrBodyTimesheetRecordSaveData: FrontendBodyTimesheetRecordSave,
): MmrBodyTimesheetRecordSave => {
  return {
    mmrId: mmrBodyTimesheetRecordSaveData.mmrId,
    mmrCalendarDays: mmrBodyTimesheetRecordSaveData.mmrCalendarDays.map((calendarDayData) => convertMmrCalendarDayDataToBackend(calendarDayData)),
    mmrCtrs: mmrBodyTimesheetRecordSaveData.mmrCtrs as MmrCtrsBody[],
  };
};

export const convertTimesheetEmployeeBodyDataToBackend = (
  apiRequestBodyTimesheetRecordSaveData: FrontendTimesheetEmployeeBodyType,
): TimesheetEmployeeBody => {
  return {
    timesheetEmployeeId: apiRequestBodyTimesheetRecordSaveData.timesheetEmployeeId,
    mmrs: apiRequestBodyTimesheetRecordSaveData.mmrs.map((mmrItem) => convertMmrBodyTimesheetRecordSaveDataToBackend(mmrItem)),
  };
};

export const convertApiRequestBodyTimesheetRecordSaveDataToBackend = (
  apiRequestBodyTimesheetRecordSaveData: FrontendApiRequestBodyTimesheetRecordSave,
): ApiRequestBodyTimesheetSave => {
  return {
    timesheetEmployees:
      apiRequestBodyTimesheetRecordSaveData.timesheetEmployees !== null
        ? apiRequestBodyTimesheetRecordSaveData.timesheetEmployees.map((employee) => convertTimesheetEmployeeBodyDataToBackend(employee))
        : null,
    timesheetStatus:
      apiRequestBodyTimesheetRecordSaveData.timesheetStatus !== null
        ? convertProjectTimesheetStatusToBackend(apiRequestBodyTimesheetRecordSaveData.timesheetStatus)
        : null,
  };
};
