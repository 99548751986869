import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { TimesheetGroupNormal } from "epcm-common/dist/Types/TimesheetGroupTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getTimesheetGroup = async (
  id: number,
  timesheetGroupId: number,
  detailLevel: DetailLevel = DetailLevel.LIMITED,
): Promise<TimesheetGroupNormal> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies TimesheetGroupNormal;
};
