import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import ConfigurationEntityTableManager from "../../configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import ConfigurationCtrLibraryStyles from "./ConfigurationCtrLibrary.module.css";

import ConfigurationCtrPopup from "../../popups/configurationctrpopup/ConfigurationCtrPopup.tsx";
import NewCtrIcon from "../../../../../../assets/images/configuration/new-ctr-icon.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import { ConfigurationScreenType, configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { ConfigurationTargetType } from "../../../../../../types/settings/GlobalConfigurationTypes.ts";
import ConfigurationLocationsStyles from "../../configurationlocations/ConfigurationLocations.module.css";

const ConfigurationCtrLibrary = () => {
  const { searchQuery } = useProjectContext();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { currentProject } = useProjectContext();

  // const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  //
  // const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  // const canCreateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigCreate);

  return (
    <div
      className={classNames(
        ConfigurationCtrLibraryStyles.contentContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          ConfigurationCtrLibraryStyles.nestedContainerPadding,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationLocationsStyles.mainContent)}
          >
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              configurationScreenType={ConfigurationScreenType.ctrLibrary}
              target={ConfigurationTargetType.project}
            />
          </div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, ConfigurationCtrLibraryStyles.actionBtnContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        <div
          onClick={() => onOpenPopup(ProjectsPagePopups.addProjectCtr, popupHandler)}
          className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
        >
          <div>New CTR Entry</div>
          <div className={classNames(ConfigurationCtrLibraryStyles.actionBtnImage)}>
            <img src={NewCtrIcon} alt="New CTR Entry" />
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.addProjectCtr)!.isOpen && (
        <ConfigurationCtrPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectCtr, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectCtr)!.isOpen}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectCtr)}
          secondaryHeaderText={currentProject.name}
        />
      )}
    </div>
  );
};
export default ConfigurationCtrLibrary;
