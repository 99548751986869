import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { TimesheetBasic, TimesheetLimited, TimesheetNormal } from "epcm-common/dist/Types/TimesheetTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getTimesheetGroupTimesheet = async (
  id: number,
  timesheetGroupId: number,
  timesheetId: number,
  detailLevel: DetailLevel = DetailLevel.BASIC,
): Promise<TimesheetLimited | TimesheetBasic | TimesheetNormal> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies TimesheetLimited | TimesheetBasic | TimesheetNormal;
};
