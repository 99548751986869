import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../config";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { ApiResponseUserGetOne } from "epcm-common/dist/Types/UserTypes";

const API = `${EPCM_API_URLS.users}`;

export const getUser = async (code: string, detailLevel: DetailLevel = DetailLevel.LIMITED): Promise<ApiResponseUserGetOne> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${code}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies ApiResponseUserGetOne;
};
