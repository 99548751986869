import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiResponseTimesheetCountersDetailed } from "epcm-common/dist/Types/TimesheetTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectTimesheetsDetailed = async (
  id: number,
  periodStart: number,
  periodEnd: number,
): Promise<ApiResponseTimesheetCountersDetailed> => {
  const params = new URLSearchParams();
  params.set("periodStart", `${periodStart}`);
  params.set("periodEnd", `${periodEnd}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/counters/timesheets/detailed`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data as ApiResponseTimesheetCountersDetailed;
};
