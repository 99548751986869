import PermissionStyles from "./ConfigurationPermissions.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ConfigurationCtrLibraryStyles from "../ctrmanagement/configurationctrlibrary/ConfigurationCtrLibrary.module.css";
import NewCtrIcon from "../../../../../assets/images/configuration/new-ctr-icon.svg";
import SearchBarSubHeader from "../searchbarsubheader/SearchBarSubHeader.tsx";
import ConfigurationEntityTableManager from "../configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import { ConfigurationScopeEnum, ConfigurationScreenType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { ConfigurationTargetType } from "../../../../../types/settings/GlobalConfigurationTypes.ts";
import { useState } from "react";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import AddUserPopup from "../../../popups/adduserpopup/AddUserPopup.tsx";

const ConfigurationPermissions = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { currentProject } = useProjectContext();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  return (
    <div
      className={classNames(
        PermissionStyles.contentContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          PermissionStyles.nestedContainerPadding,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
        )}
      >
        <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Search Users"} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, PermissionStyles.mainContent)}>
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              configurationScreenType={ConfigurationScreenType.permissions}
              target={ConfigurationTargetType.project}
            />
          </div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, PermissionStyles.actionBtnContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

        <div
          onClick={() => onOpenPopup(ProjectsPagePopups.addProjectUser, popupHandler)}
          className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
        >
          <div>Add User</div>
          <div className={classNames(ConfigurationCtrLibraryStyles.actionBtnImage)}>
            <img src={NewCtrIcon} alt="New User" />
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.addProjectUser)!.isOpen && (
        <AddUserPopup
          scope={ConfigurationScopeEnum.PROJECT}
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectUser, popupHandler)}
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectUser)!.isOpen}
          secondaryHeaderText={`Project: ${currentProject.name}`}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectUser)}
        />
      )}
    </div>
  );
};

export default ConfigurationPermissions;
