import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import CtrManagementStyles from "./CtrManagement.module.css";

import { NavLink, Outlet, useLocation } from "react-router-dom";

import { useMemo, useState } from "react";
import { ProjectContextType, useProjectContext } from "../../../ProjectsUtils.ts";
import { CtrMenuItemEnum, CtrMenuItemType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { AppRoutesPaths } from "../../../../../types/AppRoutesTypes.ts";
import { SearchBar } from "../../../../../ui/searchbar/SearchBar.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

const ctrManagementHeaders: CtrMenuItemType[] = [
  {
    title: CtrMenuItemEnum.library,
    url: AppRoutesPaths.projectConfigurationsLibrary,
  },
  {
    title: CtrMenuItemEnum.mapping,
    url: AppRoutesPaths.projectConfigurationsMapping,
  },
];

const CtrManagement = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const location = useLocation();
  const { currentProject } = useProjectContext();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);

  const searchBarPlaceholder: string | undefined = useMemo(() => {
    const pathSegments = location.pathname.split("/");

    if (pathSegments.includes(AppRoutesPaths.projectConfigurationsMapping)) {
      return "Search mapping...";
    } else if (pathSegments.includes(AppRoutesPaths.projectConfigurationsLibrary)) {
      return "Search library...";
    } else {
      return " ";
    }
  }, [location]);

  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.flex1,
        GlobalStyles.gap3,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          CtrManagementStyles.mainContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
        )}
      >
        <div className={classNames(CtrManagementStyles.ctrTitleContainer, GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          <div className={classNames(CtrManagementStyles.ctrTitleItem)}>CTR Management</div>
          <div className={classNames(GlobalStyles.flex6, GlobalStyles.flex3)} />
          {canListConfiguration && (
            <div className={classNames(GlobalStyles.flex2)}>
              <SearchBar
                placeholder={searchBarPlaceholder}
                setSearchQuery={setSearchQuery}
                searchBarContainerStyles={CtrManagementStyles.searchInputContainer}
              />
            </div>
          )}
        </div>
        <div className={classNames(CtrManagementStyles.nestedContainerPadding)}>
          <div className={classNames(CtrManagementStyles.projectMenuContainer, GlobalStyles.flex, GlobalStyles.gap)}>
            {canListConfiguration &&
              ctrManagementHeaders.map((ctrMenuItem) => (
                <NavLink to={`${ctrMenuItem.url}`} key={ctrMenuItem.title}>
                  {({ isActive }) => (
                    <div className={classNames(CtrManagementStyles.projectMenuItem, isActive && CtrManagementStyles.projectMenuItemActive)}>
                      {ctrMenuItem.title}
                    </div>
                  )}
                </NavLink>
              ))}
          </div>
        </div>
        {canListConfiguration && <Outlet context={{ currentProject: currentProject, searchQuery: searchQuery } satisfies ProjectContextType} />}
      </div>
    </div>
  );
};

export default CtrManagement;
