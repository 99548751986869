import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import NotInTimesheetTooltipStyles from "./NotInTimesheetTooltip.module.css";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";

import { FC, useEffect, useMemo } from "react";
import { QueryModelWithCountAndData, QueryNames, SubQueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { useEpcmProjectTimesheetAnalytics } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsanalytics/useEpcmProjectTimesheetAnalytics.ts";
import { convertDateToMilSecs } from "../../../../../../../../utils/DateManipulation.ts";
import { FrontendProjectEmployeeNotInTimesheet } from "../../../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { capitalizeFirstLetter } from "../../../../../../../../utils/StringManipulation.ts";

interface TimesheetEmployeesNotInTimesheetTooltipProps {
  projectId: number;
  periodStart: Date | null;
  periodEnd: Date | null;
}

const NotInTimesheetTooltip: FC<TimesheetEmployeesNotInTimesheetTooltipProps> = ({ projectId, periodEnd, periodStart }) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectNotInTimesheetEmployees } = useEpcmProjectTimesheetAnalytics();

  const timesheetEmployeesNotInTimesheetQuery = useQuery({
    queryKey: [
      QueryNames.ProjectTimesheetAnalyticsEmployees,
      projectId,
      periodStart,
      periodEnd,
      SubQueryNames.inTooltip,
      SubQueryNames.notInTimesheet,
    ],
    queryFn: () => getAllProjectNotInTimesheetEmployees(projectId, convertDateToMilSecs(periodStart!), convertDateToMilSecs(periodEnd!)),
    enabled: isAuthorized && !!periodStart && !!periodEnd,
    select: (data) => data as QueryModelWithCountAndData<FrontendProjectEmployeeNotInTimesheet>,
  });

  const timesheetEmployeesNotInTimesheetData = useMemo(() => {
    return timesheetEmployeesNotInTimesheetQuery.data;
  }, [timesheetEmployeesNotInTimesheetQuery]);

  useEffect(() => {
    return () => {
      if (periodStart && periodEnd) {
        queryClient
          .cancelQueries({
            queryKey: [
              QueryNames.ProjectTimesheetAnalyticsEmployees,
              projectId,
              periodStart,
              periodEnd,
              SubQueryNames.inTooltip,
              SubQueryNames.notInTimesheet,
            ],
          })
          .then(() => {
            console.log(`Query with name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for project ${projectId} was cancelled`);
          });
      }
    };
  }, [queryClient, projectId, periodStart, periodEnd]);

  const firstTenEmployees = useMemo(() => {
    if (timesheetEmployeesNotInTimesheetData && timesheetEmployeesNotInTimesheetData.data.length >= 10) {
      return timesheetEmployeesNotInTimesheetData?.data.slice(0, 10);
    } else {
      return timesheetEmployeesNotInTimesheetData?.data;
    }
  }, [timesheetEmployeesNotInTimesheetData]);

  const totalTimesheetEmployeesNotInTimesheetMinus10 = useMemo(() => {
    if (timesheetEmployeesNotInTimesheetData && timesheetEmployeesNotInTimesheetData.data.length >= 10) {
      return timesheetEmployeesNotInTimesheetData.count - 10;
    } else {
      return 0;
    }
  }, [timesheetEmployeesNotInTimesheetData]);
  return (
    <div
      className={classNames(NotInTimesheetTooltipStyles.tooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap01)}
    >
      {timesheetEmployeesNotInTimesheetData ? (
        timesheetEmployeesNotInTimesheetData.count > 0 &&
        firstTenEmployees &&
        firstTenEmployees.map((employee, index) => {
          return (
            <div
              key={employee.projectEmployeeId + index}
              className={classNames(
                NotInTimesheetTooltipStyles.textElement,
                NotInTimesheetTooltipStyles.tooltipItemContainer,
                index === firstTenEmployees.length - 1 && NotInTimesheetTooltipStyles.lastTooltipItem,
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap025,
              )}
            >
              <div className={classNames(NotInTimesheetTooltipStyles.subHeader)}>{employee.employeeName}</div>
              <div className={classNames(NotInTimesheetTooltipStyles.groupName)}>
                {employee.timesheetGroupName ? `Group: ${capitalizeFirstLetter(employee.timesheetGroupName)} ` : "Not in Group"}
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
      <div>
        {totalTimesheetEmployeesNotInTimesheetMinus10 > 0 ? (
          <div
            className={classNames(
              NotInTimesheetTooltipStyles.subHeader,
              NotInTimesheetTooltipStyles.tooltipItemContainer,
              NotInTimesheetTooltipStyles.moreEmployees,
            )}
          >
            {totalTimesheetEmployeesNotInTimesheetMinus10} MORE EMPLOYEES
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default NotInTimesheetTooltip;
