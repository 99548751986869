import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import SearchBarSubHeader from "../searchbarsubheader/SearchBarSubHeader.tsx";

import ConfigurationEntityTableManager from "../configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import ConfigurationTradesStyles from "./ConfigurationTrades.module.css";
import NewCtrIcon from "../../../../../assets/images/configuration/new-ctr-icon.svg";
import ConfigurationPositionStyles from "../configurationpositions/ConfigurationPositions.module.css";
// import ConfigurationTradesPopup from "../popups/configurationtradespopup/ConfigurationTradesPopup.tsx";
import { ConfigurationScreenType, configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { ConfigurationTargetType } from "../../../../../types/settings/GlobalConfigurationTypes.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import ConfigurationTradesPopup from "../popups/configurationtradespopup/ConfigurationTradesPopup.tsx";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

const ConfigurationTrades = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { currentProject } = useProjectContext();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canCreateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigCreate);
  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);

  return (
    <div
      className={classNames(
        ConfigurationPositionStyles.contentContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
          ConfigurationPositionStyles.nestedContainerPadding,
        )}
      >
        {canListConfiguration && <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Position Trades"} />}
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationPositionStyles.mainContent)}>
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              configurationScreenType={ConfigurationScreenType.trades}
              target={ConfigurationTargetType.project}
            />
          </div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, ConfigurationTradesStyles.actionBtnContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        {canCreateConfiguration && (
          <div
            onClick={() => onOpenPopup(ProjectsPagePopups.addProjectTrade, popupHandler)}
            className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
          >
            <div>New Position Trade</div>
            <div className={classNames(ConfigurationTradesStyles.actionBtnImage)}>
              <img src={NewCtrIcon} alt="New  Position Trade" />
            </div>
          </div>
        )}
      </div>
      {popupHandler.get(ProjectsPagePopups.addProjectTrade)!.isOpen && (
        <ConfigurationTradesPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectTrade, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectTrade)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectTrade)}
        />
      )}
    </div>
  );
};
export default ConfigurationTrades;
