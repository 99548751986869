import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";

import NewDisciplineIcon from "../../../../assets/images/configuration/new-discipline-icon.svg";

import GlobalConfigurationCtrLibraryStyles from "./GlobalConfigurationCtrLibrary.module.css";
import SearchBarSubHeader from "../../../projects/project/configuration/searchbarsubheader/SearchBarSubHeader.tsx";
import ConfigurationEntityTableManager from "../../../projects/project/configuration/configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import { ConfigurationTargetType, GlobalConfigurationScreenType } from "../../../../types/settings/GlobalConfigurationTypes.ts";
import { SettingsPagePopups, useSettingsPopups } from "../../use-settings-pupups.ts";
import GlobalConfigurationCtrPopup from "../popups/globalconfigurationtradespopup/GlobalConfigurationCtrPopup.tsx";
import { configurationsPopupType } from "../../../../types/projects/ConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../types/Roles.ts";

const GlobalConfigurationCtrLibrary = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canCreateConfiguration = canPerformGlobalAction(GlobalAction.ConfigCreate);

  return (
    <div
      className={classNames(
        GlobalConfigurationCtrLibraryStyles.paddingContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap3,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
          GlobalConfigurationCtrLibraryStyles.contentContainer,
        )}
      >
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
            GlobalStyles.overflowAutoFullHeight,
            GlobalConfigurationCtrLibraryStyles.nestedContainerPadding,
          )}
        >
          <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Position Ctr Code"} />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap,
                GlobalConfigurationCtrLibraryStyles.mainContent,
              )}
            >
              <ConfigurationEntityTableManager
                searchQuery={searchQuery}
                globalConfigurationScreenType={GlobalConfigurationScreenType.ctrLibrary}
                target={ConfigurationTargetType.global}
              />
            </div>
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalConfigurationCtrLibraryStyles.actionBtnContainer)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          {canCreateConfiguration && (
            <div
              onClick={() => onOpenPopup(SettingsPagePopups.addGlobalCtr, popupHandler)}
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
            >
              <div>New Position CTR Code</div>
              <div className={classNames(GlobalConfigurationCtrLibraryStyles.actionBtnImage)}>
                <img src={NewDisciplineIcon} alt="New Position Ctr Code" />
              </div>
            </div>
          )}
        </div>
      </div>
      {popupHandler.get(SettingsPagePopups.addGlobalCtr)!.isOpen && (
        <GlobalConfigurationCtrPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(SettingsPagePopups.addGlobalCtr, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.addGlobalCtr)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.addGlobalCtr)}
        />
      )}
    </div>
  );
};
export default GlobalConfigurationCtrLibrary;
