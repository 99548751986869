import { FC, PropsWithChildren } from "react";

import InTimesheetTooltip from "./intimesheetstooltip/InTimesheetTooltip.tsx";
import NotInTimesheetTooltip from "./notintimesheettooltip/NotInTimesheetTooltip.tsx";
import {
  ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum,
  ProjectTimesheetAnalyticsListButtonTypesEnum,
} from "../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import TimesheetNonApprovedTooltip from "./timesheetgroupstooltip/TimesheetNonFinalizedTooltip.tsx";

interface TimesheetListItemTooltipProps {
  tooltipType: ProjectTimesheetAnalyticsListButtonTypesEnum;
  secondaryTooltipType?: ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum;
  projectId: number;
  periodStart: Date | null;
  periodEnd: Date | null;
}

const TimesheetListItemTooltip: FC<PropsWithChildren<TimesheetListItemTooltipProps>> = ({
  secondaryTooltipType,
  tooltipType,
  periodStart,
  periodEnd,
  projectId,
}) => {
  return (
    <>
      {tooltipType === ProjectTimesheetAnalyticsListButtonTypesEnum.timesheetItems ? (
        <TimesheetNonApprovedTooltip projectId={projectId} periodStart={periodStart} periodEnd={periodEnd} />
      ) : tooltipType === ProjectTimesheetAnalyticsListButtonTypesEnum.timesheetEmployees ? (
        secondaryTooltipType === ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum.employeesInTimesheets ? (
          <InTimesheetTooltip projectId={projectId} periodStart={periodStart} periodEnd={periodEnd} />
        ) : secondaryTooltipType === ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum.employeesNotInTimesheets ? (
          <NotInTimesheetTooltip projectId={projectId} periodStart={periodStart} periodEnd={periodEnd} />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default TimesheetListItemTooltip;
