import { ProjectDetailed, ProjectLimited } from "epcm-common/dist/Types/ProjectTypes.ts";
import {
  convertBackendStatusToFrontend,
  FrontendProjectDetailedType,
  FrontendProjectLimitedType,
  FrontendProjectNormalType,
} from "../../ProjectsApiTypes.ts";
import { ProjectEmployeeToMmrStatus } from "epcm-common/dist/Types/ProjectEmployeeTypes";
import { convertMmrStatusToFrontend } from "./projectemployees/ProjectEmployeeMmrApiConverter.ts";
import { FrontendProjectEmployeeToMmrType } from "../../ProjectEmployeesApiTypes.ts";
import { ProjectNormal } from "epcm-common/dist/Types/ProjectTypes";

export const convertSingleProjectLimitedData = (projectData: ProjectLimited): FrontendProjectLimitedType => {
  return {
    id: projectData.id,
    name: projectData.name,
  };
};
export const convertSingleProjectNormalData = (projectData: ProjectNormal): FrontendProjectNormalType => {
  return {
    ...convertSingleProjectLimitedData(projectData),
    code: projectData.code,
    projectLocation: projectData.projectLocation,
    dateOfSign: new Date(projectData.dateOfSign),
    duration: projectData.duration,
    status: convertBackendStatusToFrontend(projectData.status),
    endDate: new Date(projectData.endDate),
  };
};
export const convertSingleProjectDetailedData = (projectData: ProjectDetailed): FrontendProjectDetailedType => {
  return {
    ...convertSingleProjectNormalData(projectData),
    creator: {
      name: ` ${projectData.creator.surname} ${projectData.creator.name}`,
      code: projectData.creator.code,
      email: projectData.creator.email,
    },
    agreementNumber: projectData.agreementNumber,
    mmrPrefix: projectData.mmrPrefix,
    overtimePrefix: projectData.overtimePrefix,
    projectLocation: projectData.projectLocation,
    projectManager: projectData.projectManager
      ? {
          name: `${projectData.projectManager.surname} ${projectData.projectManager.name} `,
          code: projectData.projectManager.code,
          email: projectData.projectManager.email,
        }
      : null,
    projectClientSeniorManager: projectData.projectClientSeniorManager,
    projectCostControlEngineer: projectData.projectCostControlEngineer
      ? {
          name: `${projectData.projectCostControlEngineer.surname} ${projectData.projectCostControlEngineer.name} `,
          code: projectData.projectCostControlEngineer.code,
          email: projectData.projectCostControlEngineer.email,
        }
      : null,

    projectClientCostControlEngineer: projectData.projectClientCostControlEngineer,
    projectControlsManager: projectData.projectControlsManager
      ? {
          name: `${projectData.projectControlsManager.surname} ${projectData.projectControlsManager.name} `,
          code: projectData.projectControlsManager.code,
          email: projectData.projectControlsManager.email,
        }
      : null,

    projectClientControlsManager: projectData.projectClientControlsManager,
  };
};

export const convertProjectsData = (projectsData: ProjectLimited[]): FrontendProjectLimitedType[] => {
  return projectsData.map((projectItem): FrontendProjectLimitedType => {
    return convertSingleProjectLimitedData(projectItem);
  });
};

export const convertProjectEmployeesData = (projectEmployeesData: ProjectEmployeeToMmrStatus[]): FrontendProjectEmployeeToMmrType[] => {
  return projectEmployeesData.map((projectEmployeeItem): FrontendProjectEmployeeToMmrType => {
    return {
      id: projectEmployeeItem.id,
      code: projectEmployeeItem.employeeCode,
      name: projectEmployeeItem.name,
      employeeId: projectEmployeeItem.employeeId,
      mmrId: projectEmployeeItem.mmrId,
      mmrStatus: convertMmrStatusToFrontend(projectEmployeeItem.mmrStatus),
      canBeDeleted: projectEmployeeItem.canBeDeleted,
    };
  });
};
