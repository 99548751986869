import { useQuery, useQueryClient } from "@tanstack/react-query";

import { FC, useEffect, useMemo } from "react";
import classNames from "classnames";
import TimesheetGroupsTooltipStyles from "./TimesheetGroupsTooltip.module.css";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { useEpcmProjectTimesheetAnalytics } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsanalytics/useEpcmProjectTimesheetAnalytics.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { QueryNames, SubQueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { convertDateToMilSecs } from "../../../../../../../../utils/DateManipulation.ts";
import { FrontendTimesheetStatusEnum } from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { FrontendProjectTimesheetCountersType } from "../../../../../../../../types/projects/ProjectTimesheetsTypes.ts";

interface TimesheetGroupsTooltipProps {
  projectId: number;
  periodStart: Date | null;
  periodEnd: Date | null;
}

const TimesheetNonApprovedTooltip: FC<TimesheetGroupsTooltipProps> = ({ projectId, periodStart, periodEnd }) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectTimesheetDetailedData } = useEpcmProjectTimesheetAnalytics();

  const timesheetNonApprovedQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsItems, projectId, periodStart, periodEnd, SubQueryNames.finalized],
    queryFn: () => getAllProjectTimesheetDetailedData(projectId, convertDateToMilSecs(periodStart!), convertDateToMilSecs(periodEnd!)),

    enabled: isAuthorized,
    select: (data) => data as FrontendProjectTimesheetCountersType,
  });

  const timesheetNonApprovedData = useMemo(() => timesheetNonApprovedQuery.data, [timesheetNonApprovedQuery.data]);

  useEffect(() => {
    return () => {
      if (periodStart && periodEnd) {
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsItems, projectId, periodStart, periodEnd, SubQueryNames.finalized],
          })
          .then(() => {
            console.log(`Query with name ${QueryNames.ProjectTimesheetAnalyticsItems} for project ${projectId} was cancelled`);
          });
      }
    };
  }, [queryClient, projectId, periodStart, periodEnd]);

  return (
    <div
      className={classNames(TimesheetGroupsTooltipStyles.tooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap005)}
    >
      <div
        className={classNames(
          TimesheetGroupsTooltipStyles.tooltipItemContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap0005,
        )}
      >
        <div className={classNames(TimesheetGroupsTooltipStyles.noTextWrap, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap2)}>
          <div className={classNames(TimesheetGroupsTooltipStyles.statusText)}>{`PENDING:`}</div>
          <div>
            {timesheetNonApprovedData?.statusCounters
              .filter((status) => status.status === FrontendTimesheetStatusEnum.PENDING)
              .map((statusCountData) => statusCountData.count)}
          </div>
        </div>
        <div className={classNames(TimesheetGroupsTooltipStyles.noTextWrap, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap2)}>
          <div className={classNames(TimesheetGroupsTooltipStyles.statusText)}>{`PREPARED:`}</div>
          <div>
            {timesheetNonApprovedData?.statusCounters
              .filter((status) => status.status === FrontendTimesheetStatusEnum.PREPARED)
              .map((statusCountData) => statusCountData.count)}
          </div>
        </div>
        <div className={classNames(TimesheetGroupsTooltipStyles.noTextWrap, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap2)}>
          <div className={classNames(TimesheetGroupsTooltipStyles.statusText)}>{`REVIEWED:`}</div>
          <div>
            {timesheetNonApprovedData?.statusCounters
              .filter((status) => status.status === FrontendTimesheetStatusEnum.REVIEWED)
              .map((statusCountData) => statusCountData.count)}
          </div>
        </div>
        <div className={classNames(TimesheetGroupsTooltipStyles.noTextWrap, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap2)}>
          <div className={classNames(TimesheetGroupsTooltipStyles.statusText)}>{`CHECKED:`}</div>
          <div>
            {timesheetNonApprovedData?.statusCounters
              .filter((status) => status.status === FrontendTimesheetStatusEnum.CHECKED)
              .map((statusCountData) => statusCountData.count)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TimesheetNonApprovedTooltip;
