import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

import { MmrBasic, MmrDetailed, MmrNormal } from "epcm-common/dist/Types/MmrTypes";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectEmployeeMmr = async (
  id: number,
  projectEmployeeId: number,
  mmrId: number,
  detailLevel?: DetailLevel,
): Promise<MmrBasic | MmrNormal | MmrDetailed> => {
  const params = new URLSearchParams();
  detailLevel && params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/employees/${projectEmployeeId}/mmr/${mmrId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data satisfies MmrBasic | MmrNormal | MmrDetailed;
};
