import { FrontendCalendarDayHours, FrontendMmrCalendarDayBody } from "./CalendarApiTypes.ts";
import { FrontendTimesheetStatusEnum } from "./ProjectTimesheetsApiTypes.ts";

export enum FrontendCtrCategoryEnum {
  COMMON_PROJECT = "COMMON - PROJECT",
  ENGINEERING = "ENGINEERING",
  PROCUREMENT = "PROCUREMENT",
  CONSTRUCTION_PRECOMM_COMMISSIONING = "CONSTRUCTION / PRECOMM / COMMISSIONING",
}

export enum FrontendCtrTypeEnum {
  DEL = "DEL",
  ACT = "ACT",
  MISC = "MISC",
}

export const FrontendCtrOptions: Array<FrontendCtrTypeEnum> = [FrontendCtrTypeEnum.DEL, FrontendCtrTypeEnum.ACT, FrontendCtrTypeEnum.MISC];
export const FrontendCtrCategoryOptions: Array<FrontendCtrCategoryEnum> = [
  FrontendCtrCategoryEnum.COMMON_PROJECT,
  FrontendCtrCategoryEnum.ENGINEERING,
  FrontendCtrCategoryEnum.PROCUREMENT,
  FrontendCtrCategoryEnum.CONSTRUCTION_PRECOMM_COMMISSIONING,
];

export interface FrontendCtrTypeLimitedType {
  type: FrontendCtrTypeEnum;
}

export interface FrontendCtrCategoryLimitedType {
  description: FrontendCtrCategoryEnum;
}

export interface FrontendCtrBasicType {
  id: number;
  code: string;
  description: string;
}

export interface FrontendCtrLimitedType extends Pick<FrontendCtrBasicType, "id" | "code"> {}

export interface FrontendCtrNormalType extends FrontendCtrBasicType {
  category: FrontendCtrCategoryEnum;
  type: FrontendCtrTypeEnum;
}

export interface FrontendBodyTimesheetRecordSave {
  mmrId: number;
  mmrCalendarDays: FrontendMmrCalendarDayBodyType[];
  mmrCtrs: FrontendMmrCtrsBody[];
}

export interface FrontendMmrCalendarDayBodyType {
  calendarDayId: number;
  isApplicable: boolean;
  // isWorkingDay: boolean; //records: FrontendBodyTimesheetRecordSave[];
}

export interface FrontendTimesheetEmployeeBodyType {
  timesheetEmployeeId: number;
  mmrs: FrontendBodyTimesheetRecordSave[];
}

export interface FrontendApiRequestBodyTimesheetRecordSave {
  timesheetEmployees: FrontendTimesheetEmployeeBodyType[] | null;
  timesheetStatus: FrontendTimesheetStatusEnum | null;
}

export interface FrontendMmrBodyTimesheetRecordSaveTest {
  mmrId: number;
  mmrCalendarDays: FrontendMmrCalendarDayBody[];
}

export interface FrontendMmrCtrsBody {
  ctrId: number | null;
  calendarDayHours: FrontendCalendarDayHours[];
}
