import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiResponseCalendarDays, CalendarDay } from "epcm-common/dist/Types/CalendarTypes.ts";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllCalendarDays = async (
  id: number,
  monthId: number,
  pageOffset?: number | undefined,
  pageSize: number = 31,
  searchFilter?: string,
): Promise<QueryModelWithPagination<CalendarDay>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/calendar-months/${monthId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);
  const responseData = response.data as ApiResponseCalendarDays;

  const totalPages = Math.ceil(responseData.count / pageSize);
  const nextPage = pageOffset !== undefined && pageOffset < totalPages ? pageOffset + 1 : null;

  return {
    data: responseData.calendarDays,
    nextPage: nextPage,
    totalCount: responseData.count,
  };
};
