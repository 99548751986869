import { AxiosRequestConfig } from "axios";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { LocationLimited } from "epcm-common/dist/Types/LocationTypes";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectLocation = async (
  id: number,
  locationId: number,
  detailLevel: DetailLevel = DetailLevel.LIMITED,
): Promise<LocationLimited> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/location/${locationId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies LocationLimited;
};
