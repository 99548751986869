import { GlobalPositionLimited } from "epcm-common/dist/Types/GlobalPositionTypes";
import {
  FrontendGlobalCategoryType,
  FrontendGlobalCtrType,
  FrontendGlobalDisciplineType,
  FrontendGlobalLocationType,
  FrontendGlobalPositionType,
  FrontendGlobalSubPositionType,
  FrontendGlobalTradeType,
} from "../ConfigurationsTypes.ts";
import { GlobalLocationLimited } from "epcm-common/dist/Types/GlobalLocation";
import { GlobalTradeLimited } from "epcm-common/dist/Types/GlobalTradeTypes";
import { GlobalCategoryLimited } from "epcm-common/dist/Types/GlobalCategoryTypes";
import { GlobalDisciplineLimited } from "epcm-common/dist/Types/GlobalDisciplineTypes";
import { GlobalCtrLimited } from "epcm-common/dist/Types/GlobalCtrTypes";
import { convertCtrCategoryToFrontend, convertCtrTypeToFrontend } from "./CtrApiConverter.ts";
import { GlobalSubPositionLimited } from "epcm-common/dist/Types/GlobalSubPositionTypes";
import { FrontendGlobalUserTypeWithRoleIds } from "../../projects/PermissionTypes.ts";
import { UserGlobal } from "epcm-common/dist/Types/UserTypes";
import { FrontendRoleNormal } from "../UsersApiTypes.ts";
import { RoleNormal } from "epcm-common/dist/Types/RoleTypes";

export const convertGlobalPositionLimitedData = (globalPositionData: GlobalPositionLimited): FrontendGlobalPositionType => {
  return {
    id: globalPositionData.id,
    code: globalPositionData.code,
    description: globalPositionData.description,
  };
};

export const convertGlobalPositionsData = (globalPositionsData: GlobalPositionLimited[]): FrontendGlobalPositionType[] => {
  return globalPositionsData.map((globalPositionData) => {
    return convertGlobalPositionLimitedData(globalPositionData);
  });
};
export const convertGlobalSubPositionLimitedData = (globalSubPositionData: GlobalSubPositionLimited): FrontendGlobalSubPositionType => {
  return {
    id: globalSubPositionData.id,
    code: globalSubPositionData.code,
    description: globalSubPositionData.description,
  };
};

export const convertGlobalSubPositionsData = (globalSubPositionsData: GlobalSubPositionLimited[]): FrontendGlobalSubPositionType[] => {
  return globalSubPositionsData.map((globalSubPositionData) => {
    return convertGlobalSubPositionLimitedData(globalSubPositionData);
  });
};
export const convertGlobalLocationLimitedData = (globalLocationData: GlobalLocationLimited): FrontendGlobalLocationType => {
  return {
    id: globalLocationData.id,
    code: globalLocationData.code,
    description: globalLocationData.description,
    workingHours: globalLocationData.workingHours,
  };
};

export const convertGlobalLocationsData = (globalLocationsData: GlobalLocationLimited[]): FrontendGlobalLocationType[] => {
  return globalLocationsData.map((globalLocationData) => {
    return convertGlobalLocationLimitedData(globalLocationData);
  });
};

export const convertGlobalTradeLimitedData = (globalTradeData: GlobalTradeLimited): FrontendGlobalTradeType => {
  return {
    id: globalTradeData.id,
    code: globalTradeData.code,
    description: globalTradeData.description,
  };
};
export const convertGlobalTradesData = (globalTradesData: GlobalTradeLimited[]): FrontendGlobalTradeType[] => {
  return globalTradesData.map((globalTradeData) => {
    return convertGlobalTradeLimitedData(globalTradeData);
  });
};

export const convertGlobalCategoryLimitedData = (globalCategoryData: GlobalCategoryLimited): FrontendGlobalCategoryType => {
  return {
    id: globalCategoryData.id,
    code: globalCategoryData.code,
    description: globalCategoryData.description,
  };
};

export const convertGlobalCategoriesData = (globalCategoriesData: GlobalCategoryLimited[]): FrontendGlobalCategoryType[] => {
  return globalCategoriesData.map((globalCategoryData) => {
    return convertGlobalCategoryLimitedData(globalCategoryData);
  });
};

export const convertGlobalDisciplineLimitedData = (globalDisciplineData: GlobalDisciplineLimited): FrontendGlobalDisciplineType => {
  return {
    id: globalDisciplineData.id,
    code: globalDisciplineData.code,
    description: globalDisciplineData.description,
  };
};
export const convertGlobalDisciplinesData = (globalDisciplinesData: GlobalDisciplineLimited[]): FrontendGlobalDisciplineType[] => {
  return globalDisciplinesData.map((globalDisciplineData) => {
    return convertGlobalDisciplineLimitedData(globalDisciplineData);
  });
};

export const convertGlobalCtrLimitedData = (globalCtrData: GlobalCtrLimited): FrontendGlobalCtrType => {
  return {
    id: globalCtrData.id,
    code: globalCtrData.code,
    description: globalCtrData.description,
    type: convertCtrTypeToFrontend(globalCtrData.type),
    category: convertCtrCategoryToFrontend(globalCtrData.category),
  };
};

export const convertGlobalCtrsData = (globalCtrsData: GlobalCtrLimited[]): FrontendGlobalCtrType[] => {
  return globalCtrsData.map((globalCtrData) => {
    return convertGlobalCtrLimitedData(globalCtrData);
  });
};

export const convertRoleData = (roleData: RoleNormal): FrontendRoleNormal => {
  return {
    id: roleData.id,
    name: roleData.name,
    description: roleData.description,
    dbName: roleData.dbName,
    isEligible: roleData.isEligible,
  };
};

export const convertRolesData = (rolesData: RoleNormal[]): FrontendRoleNormal[] => {
  return rolesData.map((roleData) => {
    return convertRoleData(roleData);
  });
};
export const convertGlobalUserRoleData = (globalUserRoleData: UserGlobal): FrontendGlobalUserTypeWithRoleIds => {
  return {
    id: parseInt(globalUserRoleData.code),
    name: `${globalUserRoleData.surname} ${globalUserRoleData.name}`,
    code: globalUserRoleData.code,
    position: globalUserRoleData.position,
    positionCode: globalUserRoleData.positionCode,
    globalRoleIds: globalUserRoleData.globalRoleIds,
  };
};
export const convertGlobalUserRolesData = (globalUserRolesData: UserGlobal[]): FrontendGlobalUserTypeWithRoleIds[] => {
  return globalUserRolesData.map((globalUserRoleData) => {
    return convertGlobalUserRoleData(globalUserRoleData);
  });
};
