import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import GroupEmployeeListStyles from "./GroupEmployeesSectionHeadStyles.module.css";

import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import TimesheetGroupSettingsPopupStyles from "../TimesheetGroupSettingsPopupStyles.module.css";
import { useInView } from "react-intersection-observer";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import { useEpcmApiUsers } from "../../../../../apicalls/users/useEpcmApiUsers.ts";
import InfiniteScrollInViewElement from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import GroupUserItem from "../groupuseritem/GroupUserItem.tsx";
import EmployeeItemSkeletonLoader from "../loaders/EmployeeItemSkeletonLoader.tsx";
import { ProjectRole } from "../../../../../types/Roles.ts";
import { useEpcmApiConfigurations } from "../../../../../apicalls/configurations/useEpcmApiConfigurations.ts";
import { ConfigurationScopeEnum } from "../../../../../types/projects/ConfigurationTypes.ts";

interface GroupEmployeesListProps {
  projectId: number;
  newSectionHead: string;
  setNewSectionHeadName: Dispatch<SetStateAction<string>>;
  setNewSectionHead: Dispatch<SetStateAction<string>>;
  searchMember: string;
  existingSectionHead?: string | null;
}

const GroupEmployeesList: FC<GroupEmployeesListProps> = ({
  newSectionHead,
  setNewSectionHead,
  projectId,
  searchMember,
  setNewSectionHeadName,
  existingSectionHead,
}) => {
  const { inView, ref } = useInView();
  const { getAllUsers } = useEpcmApiUsers();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getAllConfigurationRoles } = useEpcmApiConfigurations();

  const projectRolesQuery = useQuery({
    queryKey: [QueryNames.ProjectRoles, projectId],
    queryFn: () => getAllConfigurationRoles(ConfigurationScopeEnum.PROJECT),
    enabled: isAuthorized,
  });

  const projectRolesData = useMemo(() => {
    return projectRolesQuery.data;
  }, [projectRolesQuery.data]);

  const timesheetEditorRoleData = useMemo(() => {
    if (projectRolesData) {
      return projectRolesData?.data.find((role) => role.name === ProjectRole.SectionHead);
    }
  }, [projectRolesData]);

  const allUsersQuery = useInfiniteQuery({
    queryKey: [QueryNames.Users, projectId, searchMember],
    queryFn: ({ pageParam }) =>
      getAllUsers(
        pageParam,
        PAGINATION_PAGE_SIZE,
        searchMember,
        projectId,
        true,
        undefined,
        [timesheetEditorRoleData!.dbName],
        existingSectionHead ? [existingSectionHead] : [],
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized && !projectRolesQuery.isLoading,
  });

  const allUsersData = useMemo(() => {
    return allUsersQuery.data?.pages.flatMap((page) => page.data);
  }, [allUsersQuery.data]);

  const totalUsersCount = useMemo(() => {
    return allUsersQuery.data?.pages.flatMap((page) => page.data).length;
  }, [allUsersQuery.data]);

  useEffect(() => {
    if (inView && !allUsersQuery.isLoading && !allUsersQuery.isFetching && !allUsersQuery.isFetchingNextPage && allUsersQuery.hasNextPage) {
      allUsersQuery.fetchNextPage();
    }
  }, [inView, allUsersQuery]);

  useEffect(() => {
    return () => {
      if (!projectRolesQuery.isLoading) {
        queryClient
          .cancelQueries({ queryKey: [QueryNames.Users, projectId, searchMember] })
          .then(() => console.log(`In project with id ${projectId}, ${QueryNames.Users} query canceled`));
      }
    };
  }, [queryClient, projectId, searchMember]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectRoles, projectId] });
      void queryClient.cancelQueries({ queryKey: [QueryNames.Users, projectId, searchMember] });
    };
  }, [queryClient, projectId]);

  const onClickOption = (event: React.MouseEvent<HTMLInputElement>, code: string, name: string) => {
    const target = event.currentTarget as HTMLInputElement;
    if (target.value === newSectionHead) {
      setNewSectionHead("");
      setNewSectionHeadName("");
    } else {
      setNewSectionHead(code); // Directly set the selected employee code
      setNewSectionHeadName(name);
    }
  };

  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.gap,
        GlobalStyles.flex1,
        GlobalStyles.flexDirectionColumn,
        GroupEmployeeListStyles.radioGroupContainer,
      )}
    >
      {allUsersData && totalUsersCount && totalUsersCount > 0 ? (
        allUsersData.map((user) => (
          <div
            key={user.code}
            className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075, GroupEmployeeListStyles.radioContainer)}
          >
            <input
              type="radio"
              value={user.code}
              checked={newSectionHead === user.code} // Ensure this is only true when the newSectionHead matches the radio button's value
              onClick={(e) => onClickOption(e, user.code, `${user.name}`)}
              className={classNames(TimesheetGroupSettingsPopupStyles.radioInput)}
            />
            <GroupUserItem name={user.name} code={user.code} />
            <InfiniteScrollInViewElement
              key={"InfiniteScrollInViewElement"}
              reference={ref}
              infiniteQueryResult={allUsersQuery}
              loaderComponent={<> </>}
            />
          </div>
        ))
      ) : allUsersQuery.isLoading ? (
        <EmployeeItemSkeletonLoader width={490}></EmployeeItemSkeletonLoader>
      ) : (
        <div
          className={classNames(
            GlobalStyles.emptyListMsgSmall,
            GlobalStyles.centerVertical,
            GlobalStyles.centerHorizontal,
            GlobalStyles.overflowHiddenFullHeight,
          )}
        >
          No Employees found
        </div>
      )}
    </div>
  );
};
export default GroupEmployeesList;
