import { QueryModelWithCount } from "../../../../types/apicallstypes/queryCommons.ts";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { FrontendTimesheetCountersEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectEmployeesByPeriodCount = async (
  id: number,
  filters?: FrontendTimesheetCountersEnum,
  periodStart?: number,
  periodEnd?: number,
): Promise<QueryModelWithCount> => {
  const params = new URLSearchParams();
  periodStart && params.set("periodStart", `${periodStart}`);
  periodEnd && params.set("periodEnd", `${periodEnd}`);
  filters && params.set("filters", filters);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/counters/employees`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data as QueryModelWithCount;
};
