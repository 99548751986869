import { AxiosRequestConfig } from "axios";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { DisciplineLimited } from "epcm-common/dist/Types/DisciplineTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectDiscipline = async (
  id: number,
  disciplineId: number,
  detailLevel: DetailLevel = DetailLevel.LIMITED,
): Promise<DisciplineLimited> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/discipline/${disciplineId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies DisciplineLimited;
};
