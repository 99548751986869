import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../config";
import { ProjectDetailed, ProjectLimited } from "epcm-common/dist/Types/ProjectTypes.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { ProjectNormal } from "epcm-common/dist/Types/ProjectTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getProject = async (
  id: number,
  detailLevel: DetailLevel = DetailLevel.LIMITED,
): Promise<ProjectLimited | ProjectNormal | ProjectDetailed> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies ProjectLimited | ProjectNormal | ProjectDetailed;
};
