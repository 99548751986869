import { Alert } from "@mui/material";
import { EpcmSnackbarMessage, EpcmToastSeverityEnum } from "../../../../types/EpcmToastProps.ts";
import classNames from "classnames";
import EpcmToastContentStyles from "./EpcmToastContent.module.css";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { LoaderPopup } from "../../../loaderpopup/LoaderPopup.tsx";
import { FileTransactionType } from "../../../../types/projects/FileTypes.ts";
import { Link } from "react-router-dom";
import { ResponseAlertPopup } from "../../../responsealertpopup/ResponseAlertPopup.tsx";
import { FC, useState } from "react";
import { useEpcmApiProjectFiles } from "../../../../apicalls/projects/projectfiles/useEpcmApiProjectFiles.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { fileSave } from "browser-fs-access";
import { ApiResponseTypeEnum } from "../../../../types/apicallstypes/queryCommons.ts";

interface EpcmToastContentProps {
  toast: EpcmSnackbarMessage;
  handleClose: (_event: React.SyntheticEvent | Event, reason?: string) => void;
}

const EpcmToastContent: FC<EpcmToastContentProps> = ({ toast, handleClose }) => {
  const { getProjectFile } = useEpcmApiProjectFiles();
  const [isItemDownloading, setIsItemDownloading] = useState(false);
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
  } = useResponseAlertPopupStateType();

  const handleDownload = (sseRequestId: number) => {
    getProjectFile(toast.projectId, sseRequestId).then((response) => {
      fileSave(response.responseData, {
        fileName: toast.fileName,
        extensions: [".xlsx"],
        description: "Excel File",
        mimeTypes: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
      })
        .then(() => {
          onOpenResponseAlertPopup();
          setResponseType(ApiResponseTypeEnum.success);
          setResponseObject({ status: 200, message: "File saved successfully!" });
        })
        .catch((error) => {
          console.error("Error saving file:", error);
          setResponseType(ApiResponseTypeEnum.error);
          setResponseObject(error.response.data);
          onOpenResponseAlertPopup();
        })
        .finally(() => {
          setIsItemDownloading(false);
        });
    });
  };

  return (
    <Alert
      key={toast.fileId}
      severity={toast?.severity || EpcmToastSeverityEnum.info}
      onClose={handleClose}
      variant="filled"
      sx={{
        minWidth: 250,
        borderRadius: 4,
        width: 1,
        mb: 1,
        height: "100%",
        backgroundColor: toast?.severity === EpcmToastSeverityEnum.error ? "#e56161" : "#72777e",
      }}
    >
      <div
        className={classNames(
          EpcmToastContentStyles.epcmContentContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.centerHorizontal,
        )}
      >
        <div className={classNames(EpcmToastContentStyles.message, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.centerHorizontal)}>
          {toast.message}
        </div>

        {isItemDownloading ? (
          <LoaderPopup isOpen={isItemDownloading} closeFn={() => {}} />
        ) : (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
            {toast.valid && toast.sseRequestId && toast.transactionType !== FileTransactionType.imported && (
              <div
                className={classNames(EpcmToastContentStyles.epcmContent, GlobalStyles.flex, GlobalStyles.centerBoth, GlobalStyles.elementWithCursor)}
                onClick={() => !isItemDownloading && toast.sseRequestId && handleDownload(toast.sseRequestId)}
              >
                Download
              </div>
            )}
            {(toast.severity === EpcmToastSeverityEnum.success || toast.severity === EpcmToastSeverityEnum.error) && (
              <div
                className={classNames(
                  EpcmToastContentStyles.navigateToFiles,
                  GlobalStyles.flex,
                  GlobalStyles.centerBoth,
                  GlobalStyles.elementWithCursor,
                )}
              >
                <Link to={`/projects/${toast.projectId}/documents`} onClick={handleClose}>
                  Go to Documents
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            onCloseResponseAlertPopup();
          }}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </Alert>
  );
};

export default EpcmToastContent;
