import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../config";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { EmployeeDetailed, EmployeeLimited } from "epcm-common/dist/Types/EmployeeTypes.ts";

const API = `${EPCM_API_URLS.employees}`;

export const getEmployee = async (id: number, detailLevel: DetailLevel = DetailLevel.LIMITED): Promise<EmployeeLimited | EmployeeDetailed> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies EmployeeLimited | EmployeeDetailed;
};
