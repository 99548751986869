import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { SearchBar } from "../../../../ui/searchbar/SearchBar.tsx";
import SearchInputStyles from "../../../../ui/searchbar/SearchInputStyles.module.css";
import SelectUserForProjectRolesPopupStyles from "./SelectUserForProjectRolePopup.module.css";
import { FrontendUserLimitedType } from "../../../../types/apicallstypes/UsersApiTypes.ts";
import GroupEmployeeListStyles from "../timesheetgroupsettingspopup/groupemployeessectionheadlist/GroupEmployeesSectionHeadStyles.module.css";
import TimesheetGroupSettingsPopupStyles from "../timesheetgroupsettingspopup/TimesheetGroupSettingsPopupStyles.module.css";
import GroupUserItem from "../timesheetgroupsettingspopup/groupuseritem/GroupUserItem.tsx";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import EmployeeItemSkeletonLoader from "../timesheetgroupsettingspopup/loaders/EmployeeItemSkeletonLoader.tsx";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useInView } from "react-intersection-observer";
import { useEpcmApiUsers } from "../../../../apicalls/users/useEpcmApiUsers.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";

interface SelectUserForProjectRolesPopupProps extends PopupType {
  selectedUser: FrontendUserLimitedType | undefined;
  setSelectedUser: Dispatch<SetStateAction<FrontendUserLimitedType | undefined>>;
}

const SelectUserForProjectRolesPopup: FC<SelectUserForProjectRolesPopupProps> = ({
  isOpen,
  closeFn,
  secondaryHeaderText,
  headerText,
  selectedUser,
  setSelectedUser,
}) => {
  const [searchMember, setSearchMember] = useState<string>("");
  const [tempSelectedUser, setTempSelectedUser] = useState<FrontendUserLimitedType | undefined>(selectedUser);
  const { inView, ref } = useInView();
  const { getAllUsers } = useEpcmApiUsers();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const allUsersQuery = useInfiniteQuery({
    queryKey: [QueryNames.Users, searchMember],
    queryFn: ({ pageParam }) => getAllUsers(pageParam, PAGINATION_PAGE_SIZE, searchMember),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allUsersData = useMemo(() => {
    return allUsersQuery.data?.pages.flatMap((page) => page.data) ?? [];
  }, [allUsersQuery.data]);

  const totalUsersCount = useMemo(() => {
    return allUsersQuery.data?.pages.flatMap((page) => page.data).length;
  }, [allUsersQuery.data]);

  useEffect(() => {
    if (inView && !allUsersQuery.isLoading && !allUsersQuery.isFetching && !allUsersQuery.isFetchingNextPage && allUsersQuery.hasNextPage) {
      allUsersQuery.fetchNextPage();
    }
  }, [inView, allUsersQuery]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.Users, searchMember] });
    };
  }, [queryClient, searchMember]);

  return (
    <PopupStructure
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: "Select User",
          action: () => {
            setSelectedUser(tempSelectedUser);
            closeFn();
          },
          disabled: tempSelectedUser?.code === selectedUser?.code,
          tooltipText: tempSelectedUser?.code === selectedUser?.code ? "No changes" : "",
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      overrideContentContainerStyleClass={""}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div
        className={classNames(SelectUserForProjectRolesPopupStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
      >
        <SearchBar
          searchBarContainerStyles={classNames(
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap05,
            SearchInputStyles.searchInputContainer,
          )}
          setSearchQuery={setSearchMember}
          placeholder={"Search for employee"}
        />
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.gap,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GroupEmployeeListStyles.radioGroupContainer,
          )}
        >
          {allUsersData && totalUsersCount && totalUsersCount > 0 ? (
            allUsersData.map((user) => (
              <div
                key={user.code}
                onClick={() => {
                  if (tempSelectedUser?.code === user.code) {
                    setTempSelectedUser(undefined);
                    return;
                  }
                  setTempSelectedUser(user);
                }}
                className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075, GroupEmployeeListStyles.radioContainer)}
              >
                <input
                  type="radio"
                  value={user.code}
                  checked={tempSelectedUser?.code === user.code} // Ensure this is only true when the newSectionHead matches the radio button's value
                  className={classNames(TimesheetGroupSettingsPopupStyles.radioInput)}
                />
                <GroupUserItem name={user.name} code={user.code} />
                <InfiniteScrollInViewElement
                  key={"InfiniteScrollInViewElement"}
                  reference={ref}
                  infiniteQueryResult={allUsersQuery}
                  loaderComponent={<> </>}
                />
              </div>
            ))
          ) : allUsersQuery.isLoading ? (
            <EmployeeItemSkeletonLoader width={"100%"}></EmployeeItemSkeletonLoader>
          ) : (
            <div
              className={classNames(
                GlobalStyles.emptyListMsgSmall,
                GlobalStyles.centerVertical,
                GlobalStyles.centerHorizontal,
                GlobalStyles.overflowHiddenFullHeight,
              )}
            >
              No Users found
            </div>
          )}
        </div>
      </div>
    </PopupStructure>
  );
};

export default SelectUserForProjectRolesPopup;
