import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useEpcmApiProjectEmployeeMutations } from "../../../../apicalls/projects/projectemployee/mutations/useEpcmApiProjectEmployeeMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { ApiResponseTypeEnum, QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { convertDateToMilSecs } from "../../../../utils/DateManipulation.ts";
import CreateReportPopupStyles from "./CreateReportPopup.module.css";
import useEpcmProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";
import { FrontendFileAction } from "../../../../types/projects/FileTypes.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useNotificationContext } from "../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { UiComponentGeneralEnum } from "../../../../types/UiComponentGeneralType.ts";
import EpcmDatepickerMui from "../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { useEpcmApiProjects } from "../../../../apicalls/projects/useEpcmApiProjects.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendProjectNormalType } from "../../../../types/apicallstypes/ProjectsApiTypes.ts";

interface CreateReportPopupProps extends PopupType {
  projectId: string;
}

const CreateReportPopup: React.FC<CreateReportPopupProps> = ({ projectId, headerText, secondaryHeaderText, isOpen, closeFn }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { addNotification, updateNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const { getProject } = useEpcmApiProjects();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { useCreateReportsMutation } = useEpcmApiProjectEmployeeMutations();
  const { processFileEvents: processFileEventsMmrExport } = useEpcmProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );

  const startFileEventsListener = async (fileId: string) => {
    await processFileEventsMmrExport(fileId);
  };

  const projectQuery = useQuery({
    queryKey: [QueryNames.Projects, projectId, DetailLevel.NORMAL],
    queryFn: () => getProject(parseInt(projectId!), DetailLevel.NORMAL),
    enabled: isAuthorized,
    select: (data) => data as FrontendProjectNormalType,
  });

  const projectData = useMemo(() => {
    return projectQuery.data;
  }, [projectQuery.data]);

  const exportReportToFileMutation = useCreateReportsMutation(parseInt(projectId!), {
    onSuccessCallback: async (data) => {
      await startFileEventsListener(data.data!.toString());
    },
    onErrorCallback: (error) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      // Do nothing
    },
  });
  const exportReportToFileFunction = useCallback(async () => {
    !!startDate &&
      !!endDate &&
      exportReportToFileMutation.mutate({
        startDateTimestamp: convertDateToMilSecs(new Date(startDate)),
        endDateTimestamp: convertDateToMilSecs(new Date(endDate)),
      });
  }, [exportReportToFileMutation]);

  const exportReportToFile = useCallback(async () => {
    await exportReportToFileFunction();
  }, [exportReportToFileFunction, onOpenResponseAlertPopup, setResponseType, setResponseObject, startFileEventsListener]);

  const areDatesInvalid = useMemo(() => {
    if (startDate === null || endDate === null) {
      return true;
    }

    if (endDate < startDate) {
      return true;
    } else {
      return false;
    }
  }, [startDate, endDate]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.Projects, projectId, DetailLevel.NORMAL] });
    };
  }, [queryClient, projectId]);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Export Report",
          disabled: areDatesInvalid,
          buttonType: PopupButtonTypeEnum.main,
          tooltipText: areDatesInvalid ? "Please select valid start and end date" : "",
          action: () => {
            void exportReportToFile();
            closeFn();
          },
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div className={classNames(CreateReportPopupStyles.contentContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
          {projectData && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
              <EpcmDatepickerMui
                label={"Report starting date"}
                date={startDate}
                setDate={setStartDate}
                theme={UiComponentGeneralEnum.SECONDARY}
                minDate={projectData.dateOfSign}
                maxDate={projectData.endDate}
              />
            </div>
          )}
          {projectData && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
              <EpcmDatepickerMui
                label={"Report ending date"}
                date={endDate}
                setDate={setEndDate}
                theme={UiComponentGeneralEnum.SECONDARY}
                minDate={projectData.dateOfSign}
                maxDate={projectData.endDate}
              />
            </div>
          )}
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();

            if (responseType === ApiResponseTypeEnum.success) {
              closeFn();
            }
          }}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </PopupStructure>
  );
};

export default CreateReportPopup;
