import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";

import { MmrGetAll } from "epcm-common/dist/Types/MmrTypes";
import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { FrontendMmrDateStatusEnum } from "../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectEmployeeMmr = async (
  id: number,
  projectEmployeeId: number,
  pageOffset?: number,
  pageSize: number = PAGINATION_PAGE_SIZE,
  mmrDateStatus?: FrontendMmrDateStatusEnum,
  searchFilter?: string,
): Promise<QueryModelWithPagination<MmrGetAll>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);
  mmrDateStatus && params.set("mmrDateStatus", `${mmrDateStatus}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/employees/${projectEmployeeId}/mmr`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);

  const responseData = response.data as QueryModelWithCountAndData<MmrGetAll>;

  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;

  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.count };
};
