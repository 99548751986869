import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { AxiosRequestConfig } from "axios";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { ApiResponseCalendarMonths } from "epcm-common/dist/Types/CalendarTypes";
import { CalendarMonthsBasic } from "epcm-common/dist/Types/CalendarMonthsTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllCalendarMonths = async (
  id: number | undefined,
  pageOffset?: number | undefined,
  pageSize: number = PAGINATION_PAGE_SIZE,
  searchFilter?: string,
): Promise<QueryModelWithPagination<CalendarMonthsBasic>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/calendar-months`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);
  const responseData = response.data as ApiResponseCalendarMonths;

  const totalPages = Math.ceil(responseData.count / pageSize);
  const nextPage = pageOffset !== undefined && pageOffset < totalPages ? pageOffset + 1 : null;

  console.log("Thanasis total pages: ", totalPages, "nextPage: ", nextPage, "count: ", responseData.count, "pageOffset: ", pageOffset);

  return {
    data: responseData.calendarMonths,
    nextPage: nextPage,
    totalCount: responseData.count,
  };
};
