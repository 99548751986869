import { QueryModelWithCount, QueryModelWithCountAndData } from "../../../../types/apicallstypes/queryCommons.ts";
import { getAllProjectTimesheetGroupsCount } from "./getAllProjectTimesheetGroupsCount.ts";
import { getAllProjectTimesheetItemsCount } from "./getAllProjectTimesheetItemsCount.ts";
import { getAllProjectTimesheetsDetailed } from "./getAllProjectTimesheetsDetailed.ts";
import { FrontendProjectEmployeeNotInTimesheet } from "../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { getAllProjectNotInTimesheetEmployees } from "./getAllProjectNotInTimesheetEmployees.ts";
import { convertProjectEmployeesNotInTimesheetLimitedData } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projectemployees/ProjectEmployeesApiConverter.ts";
import { getAllProjectEmployeesByPeriodCount } from "./getAllProjectEmployeesByPeriodCount.ts";
import { convertTimesheetsCountersDetailedData } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetgroups/ProjectTimesheetGroupsApiConverters.ts";
import { FrontendProjectTimesheetCountersType, FrontendTimesheetCountersEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";
import { FrontendTimesheetStatusEnum } from "../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";

interface ApiDefinition {
  getAllProjectEmployeesByPeriodCount: (
    id: number,
    filters?: FrontendTimesheetCountersEnum,
    periodStart?: number,
    periodEnd?: number,
  ) => Promise<QueryModelWithCount>;

  getAllProjectNotInTimesheetEmployees: (
    id: number,
    periodStart: number,
    periodEnd: number,
  ) => Promise<QueryModelWithCountAndData<FrontendProjectEmployeeNotInTimesheet>>;

  getAllProjectTimesheetGroupsCount: (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ) => Promise<QueryModelWithCount>;

  getAllProjectTimesheetItemsCount: (
    id: number,
    periodStart: number,
    periodEnd: number,
    status?: FrontendTimesheetStatusEnum,
  ) => Promise<QueryModelWithCount>;

  getAllProjectTimesheetDetailedData: (id: number, periodStart: number, periodEnd: number) => Promise<FrontendProjectTimesheetCountersType>;
}

export const useEpcmProjectTimesheetAnalytics = (): ApiDefinition => {
  const getAllProjectEmployeesByPeriodCountConverted = async (
    id: number,
    filters?: FrontendTimesheetCountersEnum,
    periodStart?: number,
    periodEnd?: number,
  ): Promise<QueryModelWithCount> => {
    const allEmployeeCounts = await getAllProjectEmployeesByPeriodCount(id, filters, periodStart, periodEnd);
    return {
      count: allEmployeeCounts.count,
    };
  };

  const getAllProjectTimesheetGroupsCountConverted = async (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ): Promise<QueryModelWithCount> => {
    const allGroupCounts = await getAllProjectTimesheetGroupsCount(id, periodStart, periodEnd, draft, final);
    return {
      count: allGroupCounts.count,
    };
  };

  const getAllProjectTimesheetItemsCountConverted = async (
    id: number,
    periodStart: number,
    periodEnd: number,
    status?: FrontendTimesheetStatusEnum,
  ): Promise<QueryModelWithCount> => {
    const allItemsCounts = await getAllProjectTimesheetItemsCount(id, periodStart, periodEnd, status);
    return {
      count: allItemsCounts.count,
    };
  };

  const getAllProjectTimesheetDetailedData = async (
    id: number,
    periodStart: number,
    periodEnd: number,
  ): Promise<FrontendProjectTimesheetCountersType> => {
    const allTimesheetsDetailedData = await getAllProjectTimesheetsDetailed(id, periodStart, periodEnd);

    return convertTimesheetsCountersDetailedData(allTimesheetsDetailedData);
  };

  const getAllProjectNotInTimesheetEmployeesConverted = async (
    id: number,
    periodStart: number,
    periodEnd: number,
  ): Promise<QueryModelWithCountAndData<FrontendProjectEmployeeNotInTimesheet>> => {
    const allNotInTimesheetEmployees = await getAllProjectNotInTimesheetEmployees(id, periodStart, periodEnd);
    return { data: convertProjectEmployeesNotInTimesheetLimitedData(allNotInTimesheetEmployees.data), count: allNotInTimesheetEmployees.count };
  };

  return {
    getAllProjectEmployeesByPeriodCount: getAllProjectEmployeesByPeriodCountConverted,
    getAllProjectTimesheetGroupsCount: getAllProjectTimesheetGroupsCountConverted,
    getAllProjectTimesheetItemsCount: getAllProjectTimesheetItemsCountConverted,
    getAllProjectTimesheetDetailedData: getAllProjectTimesheetDetailedData,
    getAllProjectNotInTimesheetEmployees: getAllProjectNotInTimesheetEmployeesConverted,
  };
};
