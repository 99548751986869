import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../config";
import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../types/apicallstypes/queryCommons.ts";
import { UserLimited } from "epcm-common/dist/Types/UserTypes.ts";
import { createProjectRolesUrlParameter } from "../../utils/createProjectRolesUrlParameter.ts";
import { createExcludedUsersUrlParameter } from "../../utils/createExcludedUsersUrlParameter.ts";

const API = `${EPCM_API_URLS.users}`;

export const getAllUsers = async (
  pageOffset?: number,
  pageSize: number = PAGINATION_PAGE_SIZE,
  searchFilter?: string,
  projectId?: number,
  hasProjectRoles?: boolean,
  hasGlobalRoles?: boolean,
  projectRoles?: string[],
  excludedUserCodes?: string[],
): Promise<QueryModelWithPagination<UserLimited>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);
  projectId && params.set("projectId", `${projectId}`);
  hasProjectRoles !== undefined && params.set("hasProjectRoles", `${hasProjectRoles}`);
  hasGlobalRoles !== undefined && params.set("hasGlobalRoles", `${hasGlobalRoles}`);
  projectRoles && createProjectRolesUrlParameter(params, projectRoles);
  excludedUserCodes && createExcludedUsersUrlParameter(params, excludedUserCodes);

  const config: AxiosRequestConfig = {
    url: `${API}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);
  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithCountAndData<UserLimited>;

  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.count };
};
